<template>
  <div>
    <b-overlay :show="loading">
      <div v-if="userList && userList.length">
        <b-row class="mb-3">
          <b-col cols="12" sm="12" md="6" lg="4">
            <b-form-input
              id="search"
              placeholder="Search User"
              size="sm"
              class="py-4"
              @keyup.enter="searchUserMethod()"
              v-model="searchUser"
              type="text"
            />
          </b-col>
        </b-row>
        <b-table responsive :items="userList" :fields="fields">
          <template #cell(change_password)="data">
            <ChangeUserPassword
              :user_id="data.item.user_id"
              :user_email="data.item.email"
            />
          </template>
          <template #cell(administrating_business_unit_name)="data">
            <AdminUnitModal :adminUnit="data.item" :index="data.index" />
          </template>
        </b-table>
        <div
          v-if="this.userList.length"
          v-observe-visibility="ScrolledToBottom"
        ></div>
      </div>

      <div v-else>
        <b-row class="mt-2">
          <b-col cols="12">
            <p class="text-primary">No users found</p>
          </b-col>
        </b-row>
      </div>
    </b-overlay>
  </div>
</template>

<script>
import axios from "axios"
import AdminUnitModal from "@/components/System_Admin/AdminUnitModal.vue"
import ChangeUserPassword from "@/components/System_Admin/ChangeUserPassword.vue"
export default {
  props: ["buiseness_id"],
  components: { AdminUnitModal, ChangeUserPassword },
  data() {
    return {
      searchUser: "",
      fields: [
        {
          key: "user_id",
          label: "ID",
        },
        {
          key: "email",
          label: "Email",
        },
        {
          key: "change_password",
          label: "Chg password",
        },
        {
          key: "created_at",
          label: "Activation Date",
        },
        {
          key: "user_name",
          label: "Name",
        },
        {
          key: "user_language",
          label: "Lang",
        },
        {
          key: "business_unit_name",
          label: "Department",
        },
        {
          key: "administrating_business_unit_name",
          label: "Admin Unit",
        },
      ],
      loading: false,
      Users: null,
      links: null,
      searchedUser: [],
    }
  },

  computed: {
    userList() {
      if (this.searchedUser.length) return this.searchedUser
      return this.Users
    },
  },
  watch: {
    buiseness_id() {
      this.getUsers(this.buiseness_id)
    },
    searchUser() {
      if (this.searchedUser.length && this.searchUser === "") {
        this.searchedUser = []
      }
    },
  },
  methods: {
    async searchUserMethod() {
      this.loading = true
      const query = this.searchUser
      const id = this.buiseness_id

      try {
        let data = {
          business_unit_id: id,
          user_name: query,
        }
        const response = await axios.get("/internaladmin/users", {
          params: data,
        })
        if (response.data?.data.length) {
          this.searchedUser = response.data?.data ?? []
          this.links = response.data?.links ?? null
        } else {
          this.searchUser = ""
          this.$store.dispatch("addToastMessage", {
            type: "danger",
            text: "User not found",
          })
        }
      } catch ({ response }) {
        this.$store.dispatch("addToastMessage", {
          type: "danger",
          text: response.data.error_message,
        })
      } finally {
        this.loading = false
      }
    },
    async getUsers(id) {
      if (id) {
        this.loading = true

        try {
          let data = {
            business_unit_id: id,
          }

          const response = await axios.get(`/internaladmin/users`, {
            params: data,
          })

          if (response.status === 200) {
            this.Users = response.data?.data ?? null
            this.links = response.data?.links ?? null
          }
        } catch ({ response }) {
          this.$store.dispatch("addToastMessage", {
            type: "danger",
            text: response.data.item.error_message,
          })
        } finally {
          this.loading = false
        }
      }
    },
    async getUsersPaginated(id, pageURL) {
      if (id && pageURL) {
        this.loading = true

        try {
          let data = {
            customer_campaign_instance_id: id,
          }

          const response = await axios.get(pageURL, {
            params: data,
          })

          if (response.status === 200) {
            this.Users = [...this.Users, ...(response.data?.data ?? [])]
            this.links = response.data?.links ?? null
          }
        } catch ({ response }) {
          this.$store.dispatch("addToastMessage", {
            type: "danger",
            text: response.data.item.error_message,
          })
        } finally {
          this.loading = false
        }
      }
    },

    ScrolledToBottom(isVisible) {
      if (!isVisible) {
        return
      }
      if (this.links.more_pages) {
        this.getUsersPaginated(this.buiseness_id, this.links.next_page_url)
      }
    },
  },
}
</script>

<style></style>
