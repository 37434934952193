<template>
  <div>
    <b-overlay :show="loading">
      <div v-if="QuizList && QuizList.length">
        <b-table responsive :items="QuizList" :fields="fields">
          <template #cell(action)="data">
            <QuizActionModal
              :index="data.index"
              :question_id="data.item.question_id"
              @getQuiz="getQuizList(game_path_id)"
            />
          </template>
        </b-table>
      </div>

      <div v-else>
        <b-row class="mt-2">
          <b-col cols="12">
            <p class="text-primary ">No Quiz Found</p>
          </b-col>
        </b-row>
      </div>
    </b-overlay>
  </div>
</template>

<script>
import axios from "axios"
import QuizActionModal from "@/components/System_Admin/QuizActionModal.vue"
export default {
  props: ["game_path_id"],
  components: { QuizActionModal },
  data() {
    return {
      fields: [
        {
          key: "step_no",
          label: "Step_no",
        },
        {
          key: "language",
          label: "Language",
        },

        {
          key: "question",
          label: "Question",
        },
        {
          key: "action",
          label: "Action",
        },
      ],
      loading: false,
      QuizList: null,
    }
  },
  watch: {
    game_path_id() {
      this.getQuizList(this.game_path_id)
    },
  },
  methods: {
    async getQuizList(id) {
      if (id) {
        this.loading = true

        try {
          let data = {
            game_path_id: id,
          }

          const response = await axios.get(`/internaladmin/quiz`, {
            params: data,
          })

          if (response.status === 200) {
            this.QuizList = response.data?.data ?? null
          }
        } catch ({ response }) {
          this.$store.dispatch("addToastMessage", {
            type: "danger",
            text: response.data.item.error_message,
          })
        } finally {
          this.loading = false
        }
      }
    },
  },
}
</script>

<style></style>
