<template>
  <div>
    <b-button
      size="sm"
      class="btn shadow-none"
      style="border:none;background:transparent"
      @click="modalShow = !modalShow"
    >
      <span
        style=""
        v-if="adminUnit.administrating_business_unit_name"
        class="text-dark font-weight-medium"
        >{{ adminUnit.administrating_business_unit_name }}</span
      >
      <span style="" v-else class="text-dark font-weight-medium"
        >Permissions</span
      >
    </b-button>

    <b-modal
      v-model="modalShow"
      :id="ModalId"
      centered
      hide-footer
      hide-header
      size="lg"
    >
      <b-overlay :show="loading">
        <b-container fluid class="p-4  ">
          <!-- Header Section -->
          <div class="d-flex align-items-center  justify-content-between">
            <h3 class="text-primary">
              {{ adminUnit.administrating_business_unit_name }}
            </h3>
            <p
              @click="modalShow = !modalShow"
              style="cursor:pointer; color:#D4D4D4"
            >
              Skip
            </p>
          </div>

          <!-- Table Section -->
          <div v-if="UserRights">
            <b-table responsive :items="UserRights" :fields="fields">
              <!-- Edit/Delete Button Template -->
              <template #cell(modify)="data">
                <div class="d-flex justify-content-between">
                  <b-button
                    variant="primary"
                    style="border-radius:11px"
                    v-if="data.item.admin_flg !== 'Y'"
                    @click="updateRights(data.item.business_unit_id)"
                    class="px-2"
                    >Add Rights</b-button
                  >
                  <b-button
                    variant="danger"
                    v-else
                    style="border-radius:11px"
                    class="px-2"
                    @click="deleteRights()"
                    >Delete Rights</b-button
                  >
                </div>
              </template>
            </b-table>
          </div>

          <div v-else>
            <b-row class="mt-2">
              <b-col cols="12">
                <p class="text-primary ">No Users Found</p>
              </b-col>
            </b-row>
          </div>
        </b-container>
      </b-overlay>
    </b-modal>
  </div>
</template>

<script>
import axios from "axios"
export default {
  name: "AdminUnitModal",
  props: ["adminUnit", "index"],

  data() {
    return {
      loading: false,
      UserRights: null,
      modalShow: false,
      fields: [
        {
          key: "business_unit_id",
          label: "ID",
        },
        {
          key: "business_unit_name",
          label: "Unit Name",
        },
        {
          key: "modify",
          label: "Modify",
        },
      ],
    }
  },
  computed: {
    ModalId() {
      return this.index.toString()
    },
  },
  methods: {
    async getUserRights(id) {
      this.loading = true

      if (id) {
        try {
          let data = {
            user_id: id,
          }
          const response = await axios.get(`/internaladmin/administrator`, {
            params: data,
          })

          if (response.status === 200) {
            this.UserRights = response.data?.data ?? null
          }
        } catch ({ response }) {
          this.$store.dispatch("addToastMessage", {
            type: "danger",
            text: response.data.error_message,
          })
        } finally {
          this.loading = false
        }
      }
    },

    async updateRights(buiseness_id) {
      const user_id = this.adminUnit.user_id
      this.loading = true
      try {
        let data = {
          business_unit_id: buiseness_id,
          user_id: user_id,
        }
        const response = await axios.post("/internaladmin/administrator", data)
        if (response.status == 200) {
          this.getUserRights(this.adminUnit.user_id)
        }
      } catch ({ response }) {
        this.$store.dispatch("addToastMessage", {
          type: "danger",
          text: response.data.error_message,
        })
      } finally {
        this.loading = false
      }
    },
    async deleteRights() {
      const user_id = this.adminUnit.user_id
      this.loading = true
      try {
        let data = {
          user_id: user_id,
        }
        const response = await axios.delete("/internaladmin/administrator", {
          params: data,
        })
        if (response.status == 200) {
          this.getUserRights(this.adminUnit.user_id)
        }
      } catch ({ response }) {
        this.$store.dispatch("addToastMessage", {
          type: "danger",
          text: response.data.error_message,
        })
      } finally {
        this.loading = false
      }
    },
  },
  mounted() {
    this.$root.$on("bv::modal::show", modalId => {
      if (modalId.componentId === this.index.toString()) {
        if (this.adminUnit) {
          this.getUserRights(this.adminUnit.user_id)
        }
      }
    })
  },
}
</script>

<style scoped>
div >>> #admin-unit___BV_modal_content_ {
  border-radius: 15px;
  border: none;
}
</style>
