<template>
  <div>
    <!-- Quiz Path Select container -->
    <b-row>
      <b-col cols="12">
        <b-overlay :show="loading">
          <div v-if="AllQuizPath" style="max-width:300px; width: 100%">
            <b-form-select v-model="selected" size="md">
              <b-form-select-option selected :value="null" disabled
                >Select a quiz path</b-form-select-option
              >
              <b-form-select-option
                v-for="quizPath in AllQuizPath"
                :value="quizPath.game_path_id"
                :key="quizPath.game_path_id"
              >
                {{ quizPath.game_path_name }}
              </b-form-select-option>
            </b-form-select>
          </div>
        </b-overlay>
      </b-col>
    </b-row>

    <div class="mt-3 mb-2">
      <b-row>
        <b-col cols="12">
          <QuizTable :game_path_id="selected" />
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import axios from "axios"
import QuizTable from "@/components/System_Admin/QuizTable.vue"
export default {
  components: { QuizTable },
  data() {
    return {
      loading: false,
      AllQuizPath: null,
      selected: null,
    }
  },
  methods: {
    async getALLQuizPath() {
      this.loading = true
      try {
        const response = await axios.get(`/internaladmin/quizpaths`)
        if (response.status === 200) {
          this.AllQuizPath = response.data?.data ?? null
        }
      } catch ({ response }) {
        this.$store.dispatch("addToastMessage", {
          type: "danger",
          text: response.data.error_message,
        })
      } finally {
        this.loading = false
      }
    },
  },
  created() {
    this.getALLQuizPath()
  },
}
</script>

<style></style>
