<template>
  <div>
    <b-button
      type="submit"
      style="border-radius:11px"
      variant="primary"
      id="show-btn"
      @click="modalShow = !modalShow"
    >
      <span style="" class="text-uppercase">Create new challenge</span>
    </b-button>

    <b-modal v-model="modalShow" centered hide-footer hide-header size="lg">
      <b-overlay :show="loading">
        <b-container fluid class="p-4  ">
          <!-- Header Section -->
          <div class="d-flex align-items-center  justify-content-between">
            <h3 class="text-primary">Create challenge</h3>
            <p
              @click="modalShow = !modalShow"
              style="cursor:pointer; color:#D4D4D4"
            >
              Skip
            </p>
          </div>

          <div class="mt-4">
            <b-card class="mb-2" body-class="p-0">
              <!-- Image Placeholder Uploading -->
              <div
                style="position:relative;"
                class="mb-3"
                v-if="!uploaded_image"
              >
                <b-img-lazy
                  class=""
                  style="object-fit: cover;width:100%; border-top-left-radius:15px;border-top-right-radius:15px;"
                  src="@/assets/Img-Placeholder.png"
                  alt="img"
                />

                <div style="position:absolute; top:5%; right:4%">
                  <b-button
                    variant="primary"
                    size="sm"
                    style="border-radius:10px"
                    @click="PickFile"
                    ><span style="font-size:13px">Choose File</span></b-button
                  >
                  <!-- File Input -->
                  <input
                    type="file"
                    style="display:none"
                    ref="InputFile"
                    accept="image/*"
                    @change="uploadFile"
                  />
                </div>
              </div>

              <!-- After Image is uploaded -->
              <div style="position:relative;" class="mb-3" v-else>
                <b-img-lazy
                  class=""
                  v-if="isImageUploadedToS3"
                  style="object-fit:cover;width:100%; max-height:250px; border-top-left-radius:15px;border-top-right-radius:15px;"
                  :src="uploaded_image"
                  alt="img"
                  fluid
                  aspect-ratio="2:1"
                />
                <div style="position:absolute; bottom:0; right:0">
                  <div
                    v-if="challengePointId"
                    class="bg-pink point-div d-flex flex-column align-items-center "
                  >
                    <span style="font-size:14px" class="pt-2">{{
                      $t("points")
                    }}</span>
                    <span style="font-size:18px" class="font-weight-bold">{{
                      getPointValue(this.challengePointId)
                    }}</span>
                    <b-icon-star
                      class="rounded-circle bg-white p-1 "
                      font-scale="1.2"
                      variant="primary"
                    ></b-icon-star>
                  </div>
                </div>
              </div>

              <b-row no-gutters class="mt-1 ">
                <b-tabs
                  content-class="mt-4"
                  class="tabs-clear my-3 "
                  style="width:100%"
                >
                  <b-row no-gutters>
                    <b-col cols="12">
                      <b-tab title="SE" class="" active>
                        <b-row class="mb-4 px-4">
                          <b-col cols="12" class="d-flex">
                            <div>
                              <b-form-input
                                v-if="challengeTitleEditSE"
                                v-model="title_se"
                                type="text"
                                required
                                class="mb-2"
                                placeholder="Add challenge title"
                                size="sm"
                              />
                              <p
                                v-else
                                class="text-primary font-weight-bold"
                                style="cursor:pointer"
                                @click="challengeTitleEditSE = true"
                              >
                                {{ title_se }}
                              </p>
                            </div>

                            <div class="ml-3">
                              <b-button
                                class="m-0 p-0 mt-n2 btn shadow-none"
                                style="border:none;background:transparent"
                                @click="challengeTitleEditSE = true"
                              >
                                <b-icon-pencil-square
                                  font-scale="1.5"
                                  class="text-dark"
                                ></b-icon-pencil-square>
                              </b-button>
                            </div>
                          </b-col>

                          <!-- Challenge Description -->
                          <b-col cols="12" class="d-flex align-items-start">
                            <div class="flex-grow-1">
                              <b-form-textarea
                                v-if="challengeDescriptionEditSE"
                                v-model="description_se"
                                type="text"
                                required
                                class="mt-2"
                                size="sm"
                                placeholder="Enter something..."
                                rows="3"
                                max-rows="6"
                              ></b-form-textarea>
                              <span
                                v-else
                                class=" font-weight-medium"
                                style="font-size:13px; color:#929292; cursor:pointer"
                                @click="challengeDescriptionEditSE = true"
                              >
                                {{ description_se }}
                              </span>
                            </div>

                            <div class="ml-3">
                              <b-button
                                class="m-0 p-0 mt-n2 btn shadow-none"
                                style="border:none;background:transparent"
                                @click="challengeDescriptionEditSE = true"
                              >
                                <b-icon-pencil-square
                                  font-scale="1.5"
                                  class="text-dark"
                                ></b-icon-pencil-square>
                              </b-button>
                            </div>
                          </b-col>
                          <!-- Challenge short Description -->
                          <b-col cols="12" class="mt-4">
                            <div class="d-flex align-items-start">
                              <b-icon-flag-fill
                                class="mt-1"
                                style="color:#4d4b8a"
                              />
                              <p class="ml-3 text-primary font-weight-medium">
                                Challenge :
                              </p>
                            </div>
                            <div class="d-flex align-items-start">
                              <div class="flex-grow-1">
                                <b-form-textarea
                                  v-if="challengeExhortionDescriptionEditSE"
                                  v-model="description_exhortion_se"
                                  type="text"
                                  required
                                  class="mt-2"
                                  size="sm"
                                  placeholder="Enter something..."
                                  rows="3"
                                  max-rows="6"
                                ></b-form-textarea>
                                <span
                                  v-else
                                  class=" font-weight-medium"
                                  style="font-size:13px; color:#929292; cursor:pointer"
                                  @click="
                                    challengeExhortionDescriptionEditSE = true
                                  "
                                >
                                  {{ description_exhortion_se }}
                                </span>
                              </div>

                              <div class="ml-3">
                                <b-button
                                  class="m-0 p-0 mt-n2 btn shadow-none"
                                  style="border:none;background:transparent"
                                  @click="
                                    challengeExhortionDescriptionEditSE = true
                                  "
                                >
                                  <b-icon-pencil-square
                                    font-scale="1.5"
                                    class="text-dark"
                                  ></b-icon-pencil-square
                                ></b-button>
                              </div>
                            </div>
                          </b-col>

                          <b-col
                            style="border-bottom:1px solid #999999;"
                            class="mx-auto mt-4"
                            cols="10"
                          ></b-col>
                        </b-row>
                      </b-tab>
                      <b-tab title="EN" class=" ">
                        <b-row class="mb-4 px-4">
                          <b-col cols="12" class="d-flex">
                            <div>
                              <b-form-input
                                v-if="challengeTitleEdit"
                                v-model="title_en"
                                type="text"
                                required
                                class="mb-2"
                                placeholder="Add challenge title"
                                size="sm"
                              />
                              <p
                                v-else
                                class="text-primary font-weight-bold"
                                style="cursor:pointer"
                                @click="challengeTitleEdit = true"
                              >
                                {{ title_en }}
                              </p>
                            </div>

                            <div class="ml-3">
                              <b-button
                                class="m-0 p-0 mt-n2 btn shadow-none"
                                style="border:none;background:transparent"
                                @click="challengeTitleEdit = true"
                              >
                                <b-icon-pencil-square
                                  font-scale="1.5"
                                  class="text-dark"
                                ></b-icon-pencil-square>
                              </b-button>
                            </div>
                          </b-col>

                          <!-- Challenge Description -->
                          <b-col cols="12" class="d-flex align-items-start">
                            <div class="flex-grow-1">
                              <b-form-textarea
                                v-if="challengeDescriptionEdit"
                                v-model="description_en"
                                type="text"
                                required
                                class="mt-2"
                                size="sm"
                                placeholder="Enter something..."
                                rows="3"
                                max-rows="6"
                              ></b-form-textarea>
                              <span
                                v-else
                                class=" font-weight-medium"
                                style="font-size:13px; color:#929292; cursor:pointer"
                                @click="challengeDescriptionEdit = true"
                              >
                                {{ description_en }}
                              </span>
                            </div>

                            <div class="ml-3">
                              <b-button
                                class="m-0 p-0 mt-n2 btn shadow-none"
                                style="border:none;background:transparent"
                                @click="challengeDescriptionEdit = true"
                              >
                                <b-icon-pencil-square
                                  font-scale="1.5"
                                  class="text-dark"
                                ></b-icon-pencil-square>
                              </b-button>
                            </div>
                          </b-col>
                          <!-- Challenge short Description -->
                          <b-col cols="12" class="mt-4">
                            <div class="d-flex align-items-start">
                              <b-icon-flag-fill
                                class="mt-1"
                                style="color:#4d4b8a"
                              />
                              <p class="ml-3 text-primary font-weight-medium">
                                Challenge :
                              </p>
                            </div>
                            <div class="d-flex align-items-start">
                              <div class="flex-grow-1">
                                <b-form-textarea
                                  v-if="challengeExhortionDescriptionEdit"
                                  v-model="description_exhortion_en"
                                  type="text"
                                  required
                                  class="mt-2"
                                  size="sm"
                                  placeholder="Enter something..."
                                  rows="3"
                                  max-rows="6"
                                ></b-form-textarea>
                                <span
                                  v-else
                                  class=" font-weight-medium"
                                  style="font-size:13px; color:#929292; cursor:pointer"
                                  @click="
                                    challengeExhortionDescriptionEdit = true
                                  "
                                >
                                  {{ description_exhortion_en }}
                                </span>
                              </div>

                              <div class="ml-3">
                                <b-button
                                  class="m-0 p-0 mt-n2 btn shadow-none"
                                  style="border:none;background:transparent"
                                  @click="
                                    challengeExhortionDescriptionEdit = true
                                  "
                                >
                                  <b-icon-pencil-square
                                    font-scale="1.5"
                                    class="text-dark"
                                  ></b-icon-pencil-square
                                ></b-button>
                              </div>
                            </div>
                          </b-col>

                          <b-col
                            style="border-bottom:1px solid #999999;"
                            class="mx-auto mt-4"
                            cols="10"
                          ></b-col>
                        </b-row>
                      </b-tab>
                    </b-col>
                  </b-row>
                </b-tabs>

                <b-row no-gutters>
                  <b-col cols="12">
                    <b-row class="px-4" align-v="center">
                      <b-col cols="12" sm="12" md="6" lg="6">
                        <b-form-group
                          label="Activity Unit"
                          label-for="activity_unit"
                          class="text-primary mb-4 font-weight-bold"
                        >
                          <b-form-select
                            v-model="activityUnitId"
                            id="activity_unit"
                            class="mb-3"
                          >
                            <b-form-select-option selected disabled value=""
                              >Select Unit</b-form-select-option
                            >
                            <b-form-select-option
                              v-for="(unit, index) in ActivityUnits"
                              :value="unit.id"
                              :key="index"
                              >{{ unit.type }}</b-form-select-option
                            >
                          </b-form-select>
                        </b-form-group>
                      </b-col>
                      <b-col cols="12" sm="12" md="6" lg="6">
                        <b-form-group
                          label="Points"
                          label-for="points_activity_unit"
                          class="text-primary mb-4 font-weight-bold"
                        >
                          <b-form-select
                            v-model="challengePointId"
                            id="points_activity_unit"
                            class="mb-3"
                          >
                            <b-form-select-option selected disabled value=""
                              >Select points</b-form-select-option
                            >
                            <b-form-select-option
                              v-for="(point, index) in challengePoint"
                              :value="point.id"
                              :key="index"
                              >{{
                                Math.trunc(point.points)
                              }}</b-form-select-option
                            >
                          </b-form-select>
                        </b-form-group>
                      </b-col>
                      <b-col cols="12" sm="12" md="6" lg="6">
                        <b-form-group
                          label="Required checkins"
                          label-for="checkins"
                          class="text-primary mb-4 font-weight-bold"
                        >
                          <b-form-select
                            v-model="checkinValue"
                            id="checkins"
                            class="mb-3"
                          >
                            <b-form-select-option selected disabled value=""
                              >Select value</b-form-select-option
                            >
                            <b-form-select-option
                              v-for="(checkin, index) in Checkins"
                              :value="checkin.value"
                              :key="index"
                              >{{ checkin.text }}</b-form-select-option
                            >
                          </b-form-select>
                        </b-form-group>
                      </b-col>

                      <b-col
                        style="border-bottom:1px solid #999999;"
                        class="mx-auto my-3"
                        cols="10"
                      ></b-col>
                    </b-row>
                  </b-col>
                  <b-col cols="12">
                    <b-row class="px-4" align-v="center">
                      <b-col cols="12" sm="12" md="6" lg="6">
                        <b-form-group
                          label="Domain"
                          label-for="domain"
                          class="text-primary mb-4 font-weight-bold"
                        >
                          <b-form-select
                            v-model="domainId"
                            id="domain"
                            class="mb-3"
                          >
                            <b-form-select-option selected disabled value=""
                              >Select domain</b-form-select-option
                            >
                            <b-form-select-option
                              v-for="(domain, index) in domains"
                              :value="domain.id"
                              :key="index"
                              >{{ domain.domain_name }}</b-form-select-option
                            >
                          </b-form-select>
                        </b-form-group>
                      </b-col>
                      <b-col cols="12" sm="12" md="6" lg="6">
                        <b-form-group
                          label="Model profiles"
                          label-for="model"
                          class="text-primary mb-5 font-weight-bold"
                        >
                          <multiselect
                            v-model="taggingSelected"
                            :options="challengeDomainModel"
                            :multiple="true"
                            class="multiSelect mb-3"
                            :close-on-select="false"
                            :searchable="false"
                            label="classification"
                            track-by="value"
                          >
                          </multiselect>
                        </b-form-group>
                      </b-col>
                      <b-col cols="12" sm="12" md="6" lg="6">
                        <b-form-group
                          label="Models"
                          label-for="models"
                          class="text-primary mb-4 font-weight-bold"
                        >
                          <b-form-select
                            v-model="challengeModelID"
                            id="models"
                            class="mb-3"
                          >
                            <b-form-select-option selected disabled value=""
                              >Select Model</b-form-select-option
                            >
                            <b-form-select-option
                              v-for="(model, index) in challengeModels"
                              :value="model.id"
                              :key="index"
                              >{{ model.model_name }}</b-form-select-option
                            >
                          </b-form-select>
                        </b-form-group>
                      </b-col>
                      <b-col cols="12" sm="12" md="6" lg="6">
                        <b-form-group
                          label="Video url"
                          label-for="videoUrl"
                          class="text-primary mb-4 font-weight-bold"
                        >
                          <b-form-input
                            id="videoUrl"
                            v-model="video_url"
                            type="text"
                            class="mb-3"
                            placeholder="Only Youtube, Vimeo, Tedtalk"
                          />
                        </b-form-group>
                      </b-col>
                      <b-col cols="12" sm="12" md="6" lg="6">
                        <b-form-group
                          :label="
                            $t('admin.create_edit_challenge.challenge_tag')
                          "
                          label-for="challenge_tag"
                          class="text-primary mb-4 font-weight-bold"
                        >
                          <b-form-input
                            id="challenge_tag"
                            v-model="challenge_tag"
                            required
                            type="text"
                            class="mb-2"
                            :placeholder="
                              $t(
                                'admin.create_edit_challenge.challenge_tag-placeholder'
                              )
                            "
                          />
                          <span
                            class="font-weight-normal"
                            style="font-size:13px; color:#929292"
                          >
                            {{
                              $t(
                                "admin.create_edit_challenge.challenge_tag-help"
                              )
                            }}
                          </span>
                        </b-form-group>
                      </b-col>
                    </b-row>

                    <b-row class="mt-3 px-4 mb-4 d-flex justify-content-center">
                      <b-col cols="12" sm="12" md="10" lg="8">
                        <b-button
                          block
                          class="mx-auto"
                          variant="primary"
                          style="border-radius:11px"
                          @click="CreateChallenge"
                          v-if="!spinnerLoading"
                          :disabled="!isValid"
                        >
                          CREATE CHALLENGE
                        </b-button>
                        <b-button
                          block
                          style="border-radius:11px"
                          variant="primary"
                          disabled
                          v-if="spinnerLoading"
                        >
                          <b-spinner small></b-spinner>
                          <span class="sr-only">Loading...</span>
                        </b-button>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
              </b-row>
            </b-card>
          </div>
        </b-container>
      </b-overlay>
    </b-modal>
  </div>
</template>

<script>
import axios from "axios"
import Multiselect from "vue-multiselect"
import { BIconFlagFill, BIconPencilSquare, BIconStar } from "bootstrap-vue"
export default {
  name: "CreateChallengeModal",
  components: { BIconFlagFill, BIconPencilSquare, BIconStar, Multiselect },

  data() {
    return {
      loading: false,
      spinnerLoading: false,
      challengeTitleEdit: false,
      challengeDescriptionEdit: false,
      challengeExhortionDescriptionEdit: false,
      challengeTitleEditSE: false,
      challengeDescriptionEditSE: false,
      challengeExhortionDescriptionEditSE: false,
      challengeDomainModel: [],
      challengePointId: "",
      challengePoint: [],
      challengeModelID: "",
      challengeModels: [],
      domainId: "",
      domains: [],
      modalShow: false,
      deleteSpinnerLoading: false,
      isImageUploadedToS3: false,
      SelectedFile: null,
      media_file_id: null,
      uploaded_image: null,
      video_url: "",
      challenge_tag: "",
      taggingSelected: [],
      title_en: "Challenge Title",
      description_en:
        "Long description of the challenge, e.g are you walking enough to collect 15,000 stepes or more, 2 days out of 7?. This mission is automatically checked off if you log enough Steps.",
      description_exhortion_en:
        "Short description of what to do, e.g. take the stair for one week",
      title_se: "Challenge Title",
      description_se:
        "Long description of the challenge, e.g are you walking enough to collect 15,000 stepes or more, 2 days out of 7?. This mission is automatically checked off if you log enough Steps.",
      description_exhortion_se:
        "Short description of what to do, e.g. take the stair for one week",
      Checkins: [
        {
          text: "1",
          value: 1,
        },
        {
          text: "2",
          value: 2,
        },
        {
          text: "3",
          value: 3,
        },
        {
          text: "4",
          value: 4,
        },
        {
          text: "5",
          value: 5,
        },
        {
          text: "6",
          value: 6,
        },
        {
          text: "7",
          value: 7,
        },
      ],
      checkinValue: "",
      ActivityUnits: [],
      activityUnitId: "",
    }
  },

  computed: {
    isValid() {
      return (
        this.title_en &&
        this.description_en &&
        this.description_exhortion_en &&
        this.title_se &&
        this.description_se &&
        this.description_exhortion_se &&
        this.challengePointId &&
        this.domainId &&
        this.taggingSelected &&
        this.challengeModelID
      )
    },
  },

  methods: {
    getPointValue(id) {
      let value
      this.challengePoint.forEach(point => {
        if (point.id === id) {
          value = Math.trunc(point.points)
        }
      })
      return value
    },
    ToggleInput() {
      this.challengeTitleEdit = false
      this.challengeDescriptionEdit = false
      this.challengeExhortionDescriptionEdit = false
      this.challengeTitleEditSE = false
      this.challengeDescriptionEditSE = false
      this.challengeExhortionDescriptionEditSE = false
      this.SelectedFile = null
      this.media_file_id = null
      this.uploaded_image = null
      this.isImageUploadedToS3 = false
      this.title_en = "Challenge Title"
      this.description_en =
        "Long description of the challenge, e.g are you walking enough to collect 15,000 stepes or more, 2 days out of 7?. This mission is automatically checked off if you log enough Steps."
      this.description_exhortion_en =
        "Short description of what to do, e.g. take the stair for one week"
      this.title_se = "Challenge Title"
      this.description_se =
        "Long description of the challenge, e.g are you walking enough to collect 15,000 stepes or more, 2 days out of 7?. This mission is automatically checked off if you log enough Steps."
      this.description_exhortion_se =
        "Short description of what to do, e.g. take the stair for one week"
      this.activityUnitId = ""
      this.checkinValue = ""
      this.challengePointId = ""
      this.taggingSelected = []
      this.challengeModelID = ""
      this.domainId = ""
    },
    PickFile() {
      this.$refs.InputFile.click()
    },
    async uploadFile(event) {
      var input = event.target
      if (input.files && input.files[0]) {
        this.SelectedFile = input.files[0]

        try {
          let data = {
            filename: this.SelectedFile.name,
            context_type: "COMM",
          }

          const response = await axios.post("/mediaobject", data)

          if (response.status === 200) {
            this.media_file_id = response.data?.data.media_file_id
            this.uploaded_image =
              response.data?.data.filepath + response.data?.data.filename
            const save_URL = response.data?.data.save_url
            // Method to call S3 upload Put Request
            this.uploadFileToS3(save_URL)
          }
        } catch ({ response }) {
          this.$store.dispatch("addToastMessage", {
            type: "danger",
            text: response.data.error_message,
          })
        }
      }
    },
    async uploadFileToS3(url) {
      this.loading = true
      try {
        const response = await fetch(url, {
          method: "PUT",
          body: this.SelectedFile,
          headers: {
            "Content-Type": this.SelectedFile.type,
          },
        })

        if (response.status === 200) {
          this.isImageUploadedToS3 = true
        }
      } catch ({ response }) {
        this.$store.dispatch("addToastMessage", {
          type: "danger",
          text: response.data.error_message,
        })
      } finally {
        this.loading = false
      }
    },
    async getChallengeDomainModels() {
      this.loading = true
      try {
        const response = await axios.get(`internaladmin/challengemodelprofiles`)

        if (response.status === 200) {
          this.challengeDomainModel = response.data?.data ?? null
        }
      } catch ({ response }) {
        this.$store.dispatch("addToastMessage", {
          type: "danger",
          text: response.data.error_message,
        })
      } finally {
        this.loading = false
      }
    },
    async getChallengePoint(id) {
      if (id) {
        this.loading = true
        try {
          let data = {
            activity_unit_id: id,
          }
          const response = await axios.get(
            `/internaladmin/activityunitpoints`,
            { params: data }
          )

          if (response.status === 200) {
            this.challengePoint = response.data?.data ?? null
          }
        } catch ({ response }) {
          this.$store.dispatch("addToastMessage", {
            type: "danger",
            text: response.data.error_message,
          })
        } finally {
          this.loading = false
        }
      }
    },
    async getActivityUnits() {
      this.loading = true
      try {
        const response = await axios.get(`/internaladmin/activityunits`)

        if (response.status === 200) {
          this.ActivityUnits = response.data?.data ?? null
        }
      } catch ({ response }) {
        this.$store.dispatch("addToastMessage", {
          type: "danger",
          text: response.data.error_message,
        })
      } finally {
        this.loading = false
      }
    },
    async getChallengeModels() {
      this.loading = true
      try {
        const response = await axios.get(`/internaladmin/domainmodels`)

        if (response.status === 200) {
          this.challengeModels = response.data?.data ?? null
        }
      } catch ({ response }) {
        this.$store.dispatch("addToastMessage", {
          type: "danger",
          text: response.data.error_message,
        })
      } finally {
        this.loading = false
      }
    },
    async getChallengeDomains() {
      this.loading = true
      try {
        const response = await axios.get(`/internaladmin/challengedomains`)

        if (response.status === 200) {
          this.domains = response.data?.data ?? null
        }
      } catch ({ response }) {
        this.$store.dispatch("addToastMessage", {
          type: "danger",
          text: response.data.error_message,
        })
      } finally {
        this.loading = false
      }
    },
    async CreateChallenge() {
      this.spinnerLoading = true
      try {
        let modelArray = []
        if (this.taggingSelected.length) {
          this.taggingSelected.forEach(model => {
            modelArray.push(model.classification)
          })
        }

        let data = {
          challenge_title_en: this.title_en,
          challenge_description_en: this.description_en,
          challenge_description_exhortion_en: this.description_exhortion_en,
          challenge_title_se: this.title_se,
          challenge_description_se: this.description_se,
          challenge_description_exhortion_se: this.description_exhortion_se,
          model_id: this.challengeModelID,
          domain_id: this.domainId,
          activity_id: this.challengePointId,
          levels: modelArray,
        }
        if (this.video_url !== "") {
          data["video_url"] = this.video_url
        }
        if (this.challenge_tag !== "") {
          data["challenge_tag"] = this.challenge_tag
        }
        if (this.checkinValue !== "") {
          data["no_of_successful_completes_goal"] = this.checkinValue
        }
        if (this.media_file_id) {
          data["media_file_id"] = this.media_file_id
        }
        const response = await axios.post("/internaladmin/challenge", data)
        if (response.status === 200) {
          this.modalShow = !this.modalShow
          this.$emit("getCustomChallenges")
          this.$store.dispatch("addToastMessage", {
            type: "success",
            text: "Challenge created successfully",
          })
        }
      } catch ({ response }) {
        this.$store.dispatch("addToastMessage", {
          type: "danger",
          text: response.data.error_message,
        })
      } finally {
        this.spinnerLoading = false
      }
    },
  },
  watch: {
    activityUnitId() {
      this.getChallengePoint(this.activityUnitId)
    },
  },

  mounted() {
    this.$root.$on("bv::modal::show", () => {
      this.ToggleInput()
      this.getChallengeDomainModels()
      this.getChallengeDomains()
      this.getChallengeModels()
      this.getActivityUnits()
    })
  },
}
</script>

<style scoped>
div >>> #edit-modal___BV_modal_content_ {
  border-radius: 15px;
  border: none;
}
.point-div {
  width: 4rem;
  height: 5.2rem;
  border-top-left-radius: 10px;
  color: #ffffff;
}

@media (max-width: 770px) {
  .margin-md {
    margin-top: 20px !important;
  }
}
</style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
