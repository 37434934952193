<template>
  <div>
    <b-overlay :show="loading">
      <!-- Top Section -->
      <b-row class="mt-2">
        <b-col cols="12" sm="12" md="12" lg="12">
          <h3 class="text-primary font-weight-bold">
            Challenges
          </h3>
        </b-col>
        <b-col cols="12" class="mt-2 mb-3">
          <CreateChallengeModal @getCustomChallenges="getChallenges" />
        </b-col>
        <b-col cols="12" sm="12" md="12">
          <b-form-select
            v-model="selectChallengeType"
            id="language"
            class="mb-3 input-container"
          >
            <b-form-select-option value="OWN">
              OUR OWN CHALLENGES
            </b-form-select-option>
            <b-form-select-option value="CUSTOM">
              CUSTOM CHALLENGES
            </b-form-select-option>
          </b-form-select>
        </b-col>
        <b-col cols="12" sm="12" md="8" lg="8" class="mt-2">
          <b-row no-gutters align-v="start">
            <b-col lg="5" md="12" class="input-field">
              <b-form-group class="with-icon mr-3">
                <b-icon-search class="text-primary" />
                <b-form-input
                  id="search"
                  name="search"
                  size="sm"
                  class="py-4"
                  @keyup.enter="searchChallenges()"
                  v-model="searchQuery"
                  type="text"
                />
              </b-form-group>
            </b-col>

            <b-col lg="2" md="12">
              <b-button
                variant="primary"
                type="submit"
                style="border-radius:11px"
                class=" mt-1 "
                @click="searchChallenges"
              >
                {{ $t("search") }}
              </b-button>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <b-row class="mt-5">
        <b-col cols="12">
          <b-table
            v-if="challenges && challenges.length && columnValues"
            :items="columnValues"
            :fields="fields"
          >
            <!-- Type Template -->

            <template #cell(type)="data">
              <span v-if="data.item.type === 'CHECKIN'" class="text-uppercase"
                >MultiCheckin</span
              >
              <span v-else>{{ data.item.type }}</span>
            </template>
            <!-- <template #cell(rating)>
              <b-form-rating size="sm" value="5" variant="warning" style="background:none"></b-form-rating>
            </template> -->
            <template #cell(active)="data">
              <span v-if="data.item.deleted_at == null">Y</span>
              <span v-else>N</span>
            </template>

            <!-- Challenge Card ttemplate -->
            <template #cell(challenge)="data">
              <b-card class="" style="max-width: 500px;" body-class="p-0">
                <div style="position:relative;" class="mb-3">
                  <b-img-lazy
                    class=""
                    fluid
                    v-if="
                      data &&
                        data.item &&
                        data.item.challenge.media_file &&
                        data.item.challenge.media_file.file_path
                    "
                    style="object-fit:cover;width:100%;max-height:156px;height:156px; border-top-left-radius:15px;border-top-right-radius:15px;"
                    :src="
                      `${data.item.challenge.media_file.file_path}${data.item.challenge.media_file.file_name}`
                    "
                    alt="img"
                  />
                  <div
                    style="position:absolute; bottom:0; right:0"
                    class="text-white"
                  >
                    <div
                      class="bg-pink point-div d-flex flex-column align-items-center "
                    >
                      <span style="font-size:14px" class="pt-2">{{
                        $t("points")
                      }}</span>
                      <span style="font-size:18px" class="font-weight-bold">{{
                        data.item.challenge.total_points
                      }}</span>

                      <b-icon-star
                        class="rounded-circle bg-white p-1 "
                        font-scale="1.2"
                        variant="primary"
                      ></b-icon-star>
                    </div>
                  </div>
                </div>

                <b-row class="px-3 pb-4" no-gutters>
                  <b-col cols="12" class="d-flex ">
                    <p class="text-primary font-weight-bold flex-grow-1">
                      {{ data.item.challenge.name }}
                    </p>
                    <div v-if="data.item.challenge.description.length > 180">
                      <b-button
                        class="m-0 p-0 btn shadow-none"
                        style="background:transparent;border:none"
                        @click="readMore = !readMore"
                      >
                        <span class="text-pink font-weight-bold"
                          >Read
                          <span v-if="!readMore"
                            >More
                            <b-icon-chevron-down
                              class="mt-1 text-pink font-weight-bold"/></span
                          ><span v-else
                            >Less
                            <b-icon-chevron-up
                              class="mt-1 text-pink font-weight-bold"/></span
                        ></span>
                      </b-button>
                    </div>
                  </b-col>
                  <b-col cols="12">
                    <div class="text-right"></div>
                    <div
                      v-if="
                        data.item.challenge.description.length > 180 &&
                          !readMore
                      "
                    >
                      <p class="font-weight-medium " style="color:#92929">
                        {{ data.item.challenge.description.slice(0, 180) }}...
                      </p>
                    </div>
                    <div v-else>
                      <p class="font-weight-medium " style="color:#92929">
                        {{ data.item.challenge.description }}
                      </p>
                    </div>
                  </b-col>
                  <b-col cols="12">
                    <div class="d-flex align-items-start">
                      <b-icon-flag-fill class="mt-1" style="color:#4d4b8a" />
                      <p class="ml-3 text-primary font-weight-medium">
                        Challenge :
                      </p>
                    </div>
                  </b-col>
                  <b-col cols="12">
                    <p class="font-weight-medium" style="color:#92929">
                      {{ data.item.challenge.description_exhortion }}
                    </p>
                  </b-col>
                </b-row>
              </b-card>
            </template>

            <!-- Edit/Delete Button Template -->
            <template #cell(modify)="data">
              <EditDeleteChallengesModal
                :challenge_id="data.item.modify"
                :index="data.index"
                @getCustomChallenges="getChallenges"
              />
            </template>
          </b-table>
          <p class="text-primary " v-else>No Challenges found</p>
          <div
            v-if="challenges && challenges.length && !searchedChallenge.length"
            v-observe-visibility="ScrolledToBottom"
          ></div>
        </b-col>
      </b-row>
    </b-overlay>
  </div>
</template>

<script>
import axios from "axios"
import EditDeleteChallengesModal from "@/components/System_Admin/EditDeleteChallengesModal.vue"
import CreateChallengeModal from "@/components/System_Admin/CreateChallengeModal.vue"
import {
  BIconSearch,
  BIconFlagFill,
  BIconChevronDown,
  BIconChevronUp,
  BIconStar,
} from "bootstrap-vue"
export default {
  components: {
    BIconSearch,
    BIconFlagFill,
    BIconChevronDown,
    BIconChevronUp,
    BIconStar,
    EditDeleteChallengesModal,
    CreateChallengeModal,
  },
  data() {
    return {
      loading: false,
      readMore: false,
      selectChallengeType: "OWN",
      searchQuery: "",
      searchedChallenge: [],
      ALLChallenges: null,
      links: null,
      fields: [
        {
          key: "name",
          label: "Challenge Name",
          sortable: true,
        },
        {
          key: "type",
          label: "Type",
          sortable: true,
        },
        {
          key: "participants",
          label: "Participants",
        },
        { key: "rating", label: "Rating" },
        { key: "active", label: "Active", sortable: true },
        { key: "challenge", label: "Challenge" },
        { key: "modify", label: "Modify" },
      ],
    }
  },
  methods: {
    async searchChallenges() {
      this.loading = true

      try {
        let data = {
          challenge_name: this.searchQuery,
          challenge_type_code: this.selectChallengeType,
        }
        const response = await axios.get("/internaladmin/challenges", {
          params: data,
        })
        this.searchedChallenge = response.data?.data ?? []
        if (!this.searchedChallenge.length) {
          this.$store.dispatch("addToastMessage", {
            type: "danger",
            text: "No challenge found for the search filter",
          })
        }
      } catch ({ response }) {
        this.$store.dispatch("addToastMessage", {
          type: "danger",
          text: response.data.error_message,
        })
      } finally {
        this.loading = false
      }
    },
    async getChallenges(url) {
      this.loading = true

      try {
        let data = {
          challenge_type_code: this.selectChallengeType,
        }
        if (url) {
          const response = await axios.get(url, { params: data })
          this.ALLChallenges = [
            ...this.ALLChallenges,
            ...(response.data?.data ?? []),
          ]
          this.links = response.data?.links ?? null
        } else {
          const response = await axios.get("/internaladmin/challenges", {
            params: data,
          })
          this.ALLChallenges = response.data?.data ?? []
          this.links = response.data?.links ?? null
        }
      } catch ({ response }) {
        this.$store.dispatch("addToastMessage", {
          type: "danger",
          text: response.data.error_message,
        })
      } finally {
        this.loading = false
      }
    },
    ScrolledToBottom(isVisible) {
      if (!isVisible) {
        return
      }
      if (this.links.more_pages) {
        this.getChallenges(this.links.next_page_url)
      }
    },
  },
  computed: {
    challenges() {
      if (this.searchedChallenge.length) return this.searchedChallenge

      return this.ALLChallenges
    },
    columnValues() {
      let arrValue = []
      if (this.challenges) {
        arrValue = this.challenges.map(v => ({
          type: v.challenge_activity.activity.unit.type,
          active: v.deleted_at,
          name: v.name,
          challenge: v,
          modify: v.challenge_activity.challenge_id,
        }))
      }
      return arrValue
    },
  },
  watch: {
    searchQuery() {
      if (this.searchedChallenge.length && this.searchQuery === "") {
        this.searchedChallenge = []
      }
    },
    selectChallengeType() {
      this.getChallenges()
    },
  },
  mounted() {
    this.getChallenges()
  },
}
</script>

<style scoped>
.point-div {
  width: 4rem;
  height: 5.2rem;
  border-top-left-radius: 10px;
  color: #ffffff;
}
.input-container {
  max-width: 100%;
}

@media (min-width: 770px) {
  .input-container {
    max-width: 400px;
  }
}
</style>
