<template>
  <b-container class="my-2 my-sm-5" fluid>
    <h1>System Administration</h1>
    <b-row>
      <b-col cols="12">
        <b-overlay :show="loading">
          <div v-if="AllCustomers" style="max-width:300px; width: 100%">
            <b-form-select v-model="selected" size="md">
              <b-form-select-option selected :value="null" disabled
                >Select a customer</b-form-select-option
              >
              <b-form-select-option
                v-for="customer in AllCustomers"
                :value="customer.business_unit_id"
                :key="customer.business_unit_id"
              >
                {{ customer.business_unit_name }}
              </b-form-select-option>
            </b-form-select>
          </div>
        </b-overlay>
      </b-col>
    </b-row>
    <!-- System Admin Section -->
    <div class="mt-3 mb-2">
      <!--System Admin Tabs -->
      <b-row class="mt-4 ">
        <b-tabs content-class="mt-4" class="tabs-clear my-3" style="width:100%">
          <!-- Organization Tab -->
          <b-tab title="Organization" class=" " active>
            <b-row no-gutters>
              <b-col cols="12">
                <Organization :buiseness_id="selected" />
              </b-col>
            </b-row>
          </b-tab>

          <!-- Users Tab -->
          <b-tab title="Users">
            <b-row no-gutters>
              <b-col cols="12">
                <!-- <StatsTab :campaign_id="selected" /> -->
                <Users :buiseness_id="selected" />
              </b-col>
            </b-row>
          </b-tab>

          <!-- start email tab -->
          <b-tab title="Start email">
            <b-row class="mt-4">
              <b-col cols="12">
                <StartEmail :buiseness_id="selected" />
              </b-col>
            </b-row>
          </b-tab>
          <!-- Orders -->
          <b-tab title="Orders">
            <b-row class="mt-4">
              <b-col cols="12">
                <Orders :buiseness_id="selected" />
              </b-col>
            </b-row>
          </b-tab>
          <!-- GameBoard Quiz -->
          <b-tab title="Gameboard Quiz">
            <b-row class="mt-4">
              <b-col cols="12">
                <GameboardQuiz />
              </b-col>
            </b-row>
          </b-tab>
          <!-- Challenges -->
          <b-tab title="Challenges">
            <b-row class="mt-4">
              <b-col cols="12">
                <Challenges />
              </b-col>
            </b-row>
          </b-tab>
          <!-- Register new Customer -->
          <b-tab title="Create New Customer">
            <b-row class="mt-4">
              <b-col cols="12">
                <CreateNewCustomer />
              </b-col>
            </b-row>
          </b-tab>
        </b-tabs>
      </b-row>
    </div>
  </b-container>
</template>

<script>
import axios from "axios"
import Organization from "@/components/System_Admin/Organization.vue"
import Users from "@/components/System_Admin/Users.vue"
import Orders from "@/components/System_Admin/Orders.vue"
import StartEmail from "@/components/System_Admin/StartEmail.vue"
import GameboardQuiz from "@/components/System_Admin/GameboardQuiz.vue"
import Challenges from "@/components/System_Admin/Challenges.vue"
import CreateNewCustomer from "@/components/System_Admin/CreateNewCustomer.vue"
export default {
  components: {
    Organization,
    Users,
    Orders,
    StartEmail,
    GameboardQuiz,
    Challenges,
    CreateNewCustomer,
  },

  data() {
    return {
      loading: false,
      AllCustomers: null,
      selected: null,
    }
  },
  methods: {
    async getALLCustomer() {
      this.loading = true
      try {
        const response = await axios.get(`/internaladmin/customers`)
        if (response.status === 200) {
          this.AllCustomers = response.data?.data ?? null
        }
      } catch ({ response }) {
        this.$store.dispatch("addToastMessage", {
          type: "danger",
          text: response.data.error_message,
        })
      } finally {
        this.loading = false
      }
    },
  },
  created() {
    this.getALLCustomer()
  },
}
</script>
