<template>
  <div>
    <b-overlay :show="loading">
      <div v-if="Persons && Persons.length">
        <b-table responsive :items="Persons" :fields="fields">
          <template #cell(client)="data">
            <span>{{ data.item.invoice_account.invoice_company_name }}</span>
          </template>
          <template #cell(email_address)="data">
            <b-form-input
              v-model="data.item.contact_email"
              type="email"
              required
              placeholder="Enter Contact Email"
            />
          </template>
          <template #cell(contact_person)="data">
            <span
              >{{ data.item.invoice_account.contact_first_name }}
              {{ data.item.invoice_account.contact_last_name }}</span
            >
          </template>
          <template #cell(email_sent_at)="data">
            <span v-if="data.item.email_log">{{
              UTCStringToDate(data.item.email_log.created_at)
            }}</span>
          </template>
          <template #cell(email_template)="data">
            <b-form-select
              v-model="data.item.emailTemplate"
              id="chooseOrganization"
              class="mb-3"
            >
              <b-form-select-option
                v-for="(template, index) in email_template"
                :value="template.value"
                :key="index"
              >
                {{ template.text }}
              </b-form-select-option>
            </b-form-select>
          </template>
          <template #cell(language)="data">
            <b-form-select
              v-model="data.item.language"
              id="chooseOrganization"
              class="mb-3"
            >
              <b-form-select-option
                v-for="(lang, index) in language"
                :value="lang.id"
                :key="index"
              >
                {{ lang.text }}
              </b-form-select-option>
            </b-form-select>
          </template>
          <template #cell(send_btn)="data">
            <b-button
              pill
              variant="primary"
              class="text-uppercase"
              @click="sendEmail(data)"
              v-if="!data.item.loading"
            >
              send email
            </b-button>
            <b-button pill variant="primary" disabled v-if="data.item.loading">
              <b-spinner small></b-spinner>
              <span class="sr-only">Loading...</span>
            </b-button>
          </template>
        </b-table>
      </div>

      <div v-else>
        <b-row class="mt-2">
          <b-col cols="12">
            <p class="text-primary ">No Person Found</p>
          </b-col>
        </b-row>
      </div>
    </b-overlay>
  </div>
</template>

<script>
import axios from "axios"
import moment from "moment"
export default {
  props: ["buiseness_id"],
  data() {
    return {
      spinnerLoading: false,
      fields: [
        {
          key: "id",
          label: "Order id",
        },
        {
          key: "order_date",
          label: "Order Date",
        },
        {
          key: "client",
          label: "Client",
        },
        {
          key: "contact_person",
          label: "Contact Person",
        },
        {
          key: "email_address",
          label: "Email address",
        },
        {
          key: "email_template",
          label: "Email template",
        },
        {
          key: "language",
          label: "Language",
        },
        {
          key: "email_sent_at",
          label: "Email sent at",
        },
        {
          key: "send_btn",
          label: "",
        },
      ],
      loading: false,
      Persons: null,
      language: [
        {
          id: "se",
          text: "SE",
        },
        {
          id: "en",
          text: "EN",
        },
        {
          id: "de",
          text: "DE",
        },
      ],
      email_template: [
        {
          text: "Start Guide Get Moving Challenge",
          value: "start_email_with_activation_code",
        },
        {
          text: "Start Guide Mental Awareness Challenge",
          value: "start_email_with_activation_code_mental",
        },
        {
          text: "Start Guide STAR-steget",
          value: "start_email_with_activation_code_star",
        },
        
      ],
    }
  },
  watch: {
    buiseness_id() {
      this.getContactPerson(this.buiseness_id)
    },
  },
  methods: {
    async getContactPerson(id) {
      if (id) {
        this.loading = true

        try {
          let data = {
            business_unit_id: id,
          }

          const response = await axios.get(`/internaladmin/startorderemail`, {
            params: data,
          })

          if (response.status === 200) {
            let persons = response.data?.data ?? null
            if (persons && persons.length) {
              persons = persons.map(person => {
                return {
                  ...person,
                  language: "se",
                  emailTemplate: "start_email_with_activation_code",
                  contact_email: person.invoice_account.contact_email,
                  loading: false,
                }
              })
              this.Persons = persons
            }
          }
        } catch ({ response }) {
          this.$store.dispatch("addToastMessage", {
            type: "danger",
            text: response.data.item.error_message,
          })
        } finally {
          this.loading = false
        }
      }
    },
    async sendEmail(data) {
      this.Persons[data.index].loading = true

      try {
        let payload = {
          order_id: data.item.id,
          email: data.item.contact_email,
          organizational_unit_id: data.item.organizational_unit_id,
          email_template: data.item.emailTemplate,
          language: data.item.language,
        }

        const response = await axios.post(
          "/internaladmin/startorderemail",
          payload
        )

        if (response.status == 200) {
          this.$store.dispatch("addToastMessage", {
            type: "success",
            text: "Email Sent",
          })
          this.getContactPerson(this.buiseness_id)
        }
      } catch ({ response }) {
        this.$store.dispatch("addToastMessage", {
          type: "danger",
          text: response.data.error_message,
        })
      } finally {
        this.Persons[data.index].loading = true
      }
    },
    UTCStringToDate(string) {
      let m = moment.Moment
      try {
        m = moment(string, true)
      } catch (e) {
        return "Invalid date"
      }
      return m.format("yyyy-MM-DD hh:mm:ss")
    },
  },
}
</script>

<style></style>
