<template>
  <div>
    <b-overlay :show="loading">
      <div v-if="Organizations">
        <b-table responsive :items="Organizations" :fields="fields"> </b-table>
      </div>

      <div v-else>
        <b-row class="mt-2">
          <b-col cols="12">
            <p class="text-primary ">No Organizations Found</p>
          </b-col>
        </b-row>
      </div>
    </b-overlay>
  </div>
</template>

<script>
import axios from "axios"
export default {
  props: ["buiseness_id"],
  data() {
    return {
      fields: [
        {
          key: "business_unit_id",
          label: "ID",
        },
        {
          key: "parent_business_unit_id",
          label: "Parent ID",
        },

        {
          key: "business_unit_name",
          label: "Organization Name",
        },
        {
          key: "activation_code",
          label: "Activation Code",
        },
        {
          key: "last_activation_date",
          label: "Activation Date",
        },
      ],
      loading: false,
      Organizations: null,
    }
  },
  watch: {
    buiseness_id() {
      this.getOrgHierarchy(this.buiseness_id)
    },
  },
  methods: {
    async getOrgHierarchy(id) {
      if (id) {
        this.loading = true

        try {
          let data = {
            business_unit_id: id,
          }

          const response = await axios.get(
            `/internaladmin/organizationhierarchy`,
            {
              params: data,
            }
          )

          if (response.status === 200) {
            this.Organizations = response.data?.data ?? null
          }
        } catch ({ response }) {
          this.$store.dispatch("addToastMessage", {
            type: "danger",
            text: response.data.item.error_message,
          })
        } finally {
          this.loading = false
        }
      }
    },
  },
}
</script>

<style></style>
