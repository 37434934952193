<template>
  <div>
    <b-button
      size="sm"
      pill
      variant="primary"
      class="px-2"
      @click="modalShow = !modalShow"
    >
      <span style="" class=" font-weight-medium">Administrate</span>
    </b-button>

    <b-modal
      v-model="modalShow"
      :id="ModalId"
      centered
      hide-footer
      hide-header
      size="lg"
    >
      <b-overlay :show="loading">
        <b-container fluid class="p-4  ">
          <!-- Header Section -->
          <div class="d-flex align-items-center  justify-content-between">
            <h3 class="text-primary font-weight-bold">Edit Customer</h3>
            <p
              @click="modalShow = !modalShow"
              style="cursor:pointer; color:#D4D4D4"
            >
              Skip
            </p>
          </div>

          <b-form>
            <!-- Invoice Information -->
            <b-row>
              <b-col cols="12" sm="12" md="6" lg="6">
                <b-form-group
                  label="Invoice Company name"
                  label-for="invoice_company_name"
                  class="text-primary font-weight-bold"
                >
                  <b-form-input
                    id="invoice_company_name"
                    name="invoice_company_name"
                    v-model="invoiceCompanyName"
                    type="text"
                    required
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <!-- Street Adress -->
              <b-col cols="12" sm="12" md="6" lg="6">
                <b-form-group
                  label="Invoice Street Address"
                  label-for="invoice_street_add"
                  class="text-primary font-weight-bold"
                >
                  <b-form-input
                    id="invoice_street_add"
                    name="invoice_street_add"
                    v-model="invoiceStreetAdd"
                    type="text"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="12" sm="12" md="6" lg="6">
                <b-form-group
                  label="Invoice Street Address 2"
                  label-for="invoice_street_add2"
                  class="text-primary font-weight-bold"
                >
                  <b-form-input
                    id="invoice_street_add2"
                    name="invoice_street_add2"
                    v-model="invoiceStreetAdd2"
                    type="text"
                  />
                </b-form-group>
              </b-col>

              <!-- Invoice Zip Code -->
              <b-col cols="12" sm="12" md="4" lg="4">
                <b-form-group
                  label="Invoice Zip Code"
                  label-for="invoice_zip_code"
                  class="text-primary font-weight-bold"
                >
                  <b-form-input
                    id="invoice_zip_code"
                    name="invoice_zip_code"
                    v-model="InvoiceZipCode"
                    type="text"
                  />
                </b-form-group>
              </b-col>
              <!-- Zip  -->
              <b-col cols="12" sm="12" md="8" lg="8">
                <b-form-group
                  label="Invoice Zip Address"
                  label-for="invoice_zip"
                  class="text-primary font-weight-bold"
                >
                  <b-form-input
                    id="invoice_zip"
                    name="invoice_zip"
                    v-model="InvoiceZip"
                    type="text"
                  />
                </b-form-group>
              </b-col>
              <!-- Invoice Reference -->
              <b-col cols="12" sm="12" md="6" lg="6">
                <b-form-group
                  label="Invoice Reference"
                  label-for="invoice_reference"
                  class="text-primary font-weight-bold"
                >
                  <b-form-input
                    id="invoice_reference"
                    name="invoice_reference"
                    v-model="invoiceReference"
                    type="text"
                  />
                </b-form-group>
              </b-col>
              <!-- Zip  -->
              <b-col cols="12" sm="12" md="6" lg="6">
                <b-form-group
                  label="Invoice Reference 2"
                  label-for="invoice_reference2"
                  class="text-primary font-weight-bold"
                >
                  <b-form-input
                    id="invoice_reference2"
                    name="invoice_reference2"
                    v-model="invoiceReference2"
                    type="text"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <div class="my-3" style="border-bottom:2px solid #dcdcdc"></div>

            <!-- Contact Information -->
            <b-row>
              <!-- Contact Full Name -->
              <b-col cols="12" sm="12" md="6" lg="6">
                <b-form-group
                  label="Contact First Name"
                  label-for="contact_first_name"
                  class="text-primary font-weight-bold"
                >
                  <b-form-input
                    id="contact_first_name"
                    name="contact_first_name"
                    v-model="contactFirstName"
                    type="text"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="12" sm="12" md="6" lg="6">
                <b-form-group
                  label="Contact Last Name"
                  label-for="contact_last_name"
                  class="text-primary font-weight-bold"
                >
                  <b-form-input
                    id="contact_last_name"
                    name="contact_last_name"
                    v-model="contactLastName"
                    type="text"
                  />
                </b-form-group>
              </b-col>
              <!-- Contact Email & Phone -->
              <b-col cols="12" sm="12" md="6" lg="6">
                <b-form-group
                  label="Contact Email"
                  label-for="contact_email"
                  class="text-primary font-weight-bold"
                >
                  <b-form-input
                    id="contact_email"
                    name="contact_email"
                    v-model="contactEmail"
                    type="text"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="12" sm="12" md="6" lg="6">
                <b-form-group
                  label="Contact Phone"
                  label-for="contact_phone"
                  class="text-primary font-weight-bold"
                >
                  <b-form-input
                    id="contact_phone"
                    name="contact_phone"
                    v-model="contactPhone"
                    type="text"
                  />
                </b-form-group>
              </b-col>
              <!-- Comment -->
              <b-col cols="12" sm="12" md="12" lg="12">
                <b-form-group
                  label="Comment"
                  label-for="comment"
                  class="text-primary font-weight-bold"
                >
                  <b-form-textarea
                    id="comment"
                    v-model="comment"
                    size="sm"
                    no-resize
                    rows="3"
                    max-rows="3"
                  ></b-form-textarea>
                </b-form-group>
              </b-col>
            </b-row>
            <div class="my-3" style="border-bottom:2px solid #dcdcdc"></div>

            <!-- Delivery Address -->
            <b-row>
              <b-col>
                <b-form-group>
                  <b-form-checkbox
                    size="lg"
                    id="showDelivery"
                    name="showDelivery"
                    v-model="showDelivery"
                  >
                    <small class="text-primary font-weight-bold"
                      >Show delivery address</small
                    >
                  </b-form-checkbox>
                </b-form-group>
              </b-col>
            </b-row>
            <transition name="fade">
              <b-row v-if="showDelivery" class="px-1">
                <b-col cols="12" md="6" lg="6">
                  <b-form-group
                    label="Delivery Company Name"
                    label-for="del_company"
                    class="text-primary font-weight-bold"
                  >
                    <b-form-input
                      id="del_company"
                      name="del_company"
                      v-model="delCompanyName"
                      type="text"
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="12" md="6"></b-col>

                <b-col cols="12" md="6" lg="6">
                  <b-form-group
                    label="Delivery Street Adress"
                    label-for="del_street"
                    class="text-primary font-weight-bold"
                  >
                    <b-form-input
                      id="del_street"
                      name="del_street"
                      v-model="delStreetAdd"
                      type="text"
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="12" md="6" lg="6">
                  <b-form-group
                    label="Delivery  Street Adress 2"
                    label-for="del_street2"
                    class="text-primary font-weight-bold"
                  >
                    <b-form-input
                      id="del_street2"
                      name="del_street2"
                      v-model="delStreetAdd2"
                      type="text"
                    />
                  </b-form-group>
                </b-col>
                <!-- Zip Code -->
                <b-col cols="12" sm="12" md="4" lg="4">
                  <b-form-group
                    label="Delivery  Zip Code"
                    label-for="del_zip_code"
                    class="text-primary font-weight-bold"
                  >
                    <b-form-input
                      id="del_zip_code"
                      name="del_zip_code"
                      v-model="delZipCode"
                      type="text"
                    />
                  </b-form-group>
                </b-col>
                <!-- Zip  -->
                <b-col
                  cols="12"
                  sm="12"
                  md="8"
                  lg="8"
                  class="text-primary font-weight-bold"
                >
                  <b-form-group
                    label="Delivery  Zip Adress"
                    label-for="del_zip"
                  >
                    <b-form-input
                      id="del_zip"
                      name="del_zip"
                      v-model="delZip"
                      type="text"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
            </transition>
          </b-form>

          <b-row align-h="end">
            <b-col cols="12" class="d-flex justify-content-end mb-3 mb-sm-2 ">
              <b-button
                variant="primary"
                style="border-radius:11px"
                class="text-uppercase px-3 "
                @click="updateInvoiceAccount"
                >Update Customer Address</b-button
              >
            </b-col>
          </b-row>
          <b-row align-h="end">
            <b-col cols="12" class="d-flex justify-content-end ">
              <b-button
                variant="primary"
                style="border-radius:11px"
                class="text-uppercase px-5"
                @click="createExternalInvoice"
                >Invoice Customer</b-button
              >
            </b-col>
          </b-row>
        </b-container>
      </b-overlay>
    </b-modal>
  </div>
</template>

<script>
import axios from "axios"
export default {
  name: "OrderAdministrateModal",
  props: ["index", "order_id"],

  data() {
    return {
      loading: false,
      modalShow: false,
      showDelivery: false,
      invoiceCompanyName: "",
      invoiceStreetAdd: "",
      invoiceStreetAdd2: "",
      InvoiceZipCode: "",
      InvoiceZip: "",
      invoiceReference: "",
      invoiceReference2: "",
      contactFirstName: "",
      contactLastName: "",
      contactEmail: "",
      contactPhone: "",
      comment: "",
      delCompanyName: "",
      delStreetAdd: "",
      delStreetAdd2: "",
      delZipCode: "",
      delZip: "",
      getInvoiceAccountInfo: null,
    }
  },
  computed: {
    ModalId() {
      return this.index.toString()
    },
    isValid() {
      return (
        this.invoiceCompanyName &&
        this.invoiceStreetAdd &&
        this.invoiceStreetAdd2 &&
        this.InvoiceZipCode &&
        this.InvoiceZip &&
        this.invoiceReference &&
        this.contactFirstName &&
        this.contactLastName &&
        this.contactEmail &&
        this.contactPhone &&
        this.comment
      )
    },
  },
  methods: {
    async getInvoiceAccount(id) {
      this.loading = true

      if (id) {
        try {
          let data = {
            order_id: id,
          }
          const response = await axios.get(`/internaladmin/invoiceaccount`, {
            params: data,
          })

          if (response.status === 200) {
            this.getInvoiceAccountInfo = response.data?.data ?? null
            if (this.getInvoiceAccountInfo) {
              this.invoiceCompanyName = this.getInvoiceAccountInfo.invoice_account.invoice_company_name
              // streetAdd
              if (
                this.getInvoiceAccountInfo.invoice_account
                  .invoice_street_address
              ) {
                this.invoiceStreetAdd = this.getInvoiceAccountInfo.invoice_account.invoice_street_address
              }
              // streetAdd2
              if (
                this.getInvoiceAccountInfo.invoice_account
                  .invoice_street_address2
              ) {
                this.invoiceStreetAdd2 = this.getInvoiceAccountInfo.invoice_account.invoice_street_address2
              }
              // Invoicezip Code
              if (this.getInvoiceAccountInfo.invoice_account.invoice_zip_code) {
                this.InvoiceZipCode = this.getInvoiceAccountInfo.invoice_account.invoice_zip_code
              }
              // Invoicezip
              if (this.getInvoiceAccountInfo.invoice_account.invoice_zip) {
                this.InvoiceZip = this.getInvoiceAccountInfo.invoice_account.invoice_zip
              }
              // Invoice Reference
              if (
                this.getInvoiceAccountInfo.invoice_account.invoice_reference
              ) {
                this.invoiceReference = this.getInvoiceAccountInfo.invoice_account.invoice_reference
              }
              // Invoice Reference2
              if (
                this.getInvoiceAccountInfo.invoice_account.invoice_reference2
              ) {
                this.invoiceReference2 = this.getInvoiceAccountInfo.invoice_account.invoice_reference2
              }
              // Contact First Name
              if (
                this.getInvoiceAccountInfo.invoice_account.contact_first_name
              ) {
                this.contactFirstName = this.getInvoiceAccountInfo.invoice_account.contact_first_name
              }
              // Contact Last Name
              if (
                this.getInvoiceAccountInfo.invoice_account.contact_last_name
              ) {
                this.contactLastName = this.getInvoiceAccountInfo.invoice_account.contact_last_name
              }
              // Contact Email
              if (this.getInvoiceAccountInfo.invoice_account.contact_email) {
                this.contactEmail = this.getInvoiceAccountInfo.invoice_account.contact_email
              }
              // Contact Phone
              if (this.getInvoiceAccountInfo.invoice_account.contact_phone) {
                this.contactPhone = this.getInvoiceAccountInfo.invoice_account.contact_phone
              }
              // Comment
              if (this.getInvoiceAccountInfo.invoice_account.comments) {
                this.comment = this.getInvoiceAccountInfo.invoice_account.comments
              }
              // Delivary Company Name
              if (this.getInvoiceAccountInfo.invoice_account.delivery_company) {
                this.delCompanyName = this.getInvoiceAccountInfo.invoice_account.delivery_company
              }
              // Delivary Street Add
              if (
                this.getInvoiceAccountInfo.invoice_account
                  .delivery_street_address
              ) {
                this.delStreetAdd = this.getInvoiceAccountInfo.invoice_account.delivery_street_address
              }

              // Delivary Zip Code
              if (
                this.getInvoiceAccountInfo.invoice_account.delivery_zip_code
              ) {
                this.delZipCode = this.getInvoiceAccountInfo.invoice_account.delivery_zip_code
              }
              // Delivary Zip
              if (this.getInvoiceAccountInfo.invoice_account.delivery_zip) {
                this.delZip = this.getInvoiceAccountInfo.invoice_account.delivery_zip
              }
            }
          }
        } catch ({ response }) {
          this.$store.dispatch("addToastMessage", {
            type: "danger",
            text: response.data.error_message,
          })
        } finally {
          this.loading = false
        }
      }
    },

    async updateInvoiceAccount() {
      this.loading = true

      try {
        let data = {
          invoice_account_id: this.getInvoiceAccountInfo.invoice_account.id,
          root_organizational_id: this.getInvoiceAccountInfo
            .organizational_unit_id,
          organizational_unit_id: this.getInvoiceAccountInfo
            .organizational_unit_id,
          invoice_company_name: this.invoiceCompanyName,
          invoice_street_address: this.invoiceStreetAdd,
          invoice_street_address2: this.invoiceStreetAdd2,
          invoice_zip_code: this.InvoiceZipCode,
          invoice_zip: this.InvoiceZip,
          invoice_email_address: this.getInvoiceAccountInfo
            .invoice_email_address,
          invoice_country_code: this.getInvoiceAccountInfo.invoice_account
            .invoice_country_code,
          invoice_reference: this.invoiceReference,
          invoice_reference2: this.invoiceReference2,
          comments: this.comment,
          contact_first_name: this.contactFirstName,
          contact_last_name: this.contactLastName,
          contact_email: this.contactEmail,
          contact_phone: this.contactPhone,
          delivery_company: this.delCompanyName,
          delivery_street_address: this.delStreetAdd,
          delivery_zip_code: this.delZipCode,
          delivery_zip: this.delZip,
          delivery_country_code: this.getInvoiceAccountInfo.invoice_account
            .delivery_country_code,
          created_at: this.getInvoiceAccountInfo.created_at,
          updated_at: this.getInvoiceAccountInfo.updated_at,
        }
        const response = await axios.put("/internaladmin/invoiceaccount", data)
        if (response.status == 200) {
          this.$store.dispatch("addToastMessage", {
            type: "success",
            text: "Invoice Account updated",
          })
          this.$emit("getOrders")
        }
      } catch ({ response }) {
        this.$store.dispatch("addToastMessage", {
          type: "danger",
          text: response.data.error_message,
        })
      } finally {
        this.loading = false
      }
    },
    async createExternalInvoice() {
      const order_id = this.order_id
      this.loading = true
      try {
        let data = {
          order_id: order_id,
        }
        const response = await axios.post("/internaladmin/createinvoice", data)
        if (response.status == 200) {
          this.$emit("getOrders")
          this.$store.dispatch("addToastMessage", {
            type: "success",
            text: "External Invoice created",
          })
        }
      } catch ({ response }) {
        this.$store.dispatch("addToastMessage", {
          type: "danger",
          text: response.data.error_message,
        })
      } finally {
        this.loading = false
      }
    },
  },
  mounted() {
    this.$root.$on("bv::modal::show", modalId => {
      if (modalId.componentId === this.index.toString()) {
        if (this.order_id) {
          this.getInvoiceAccount(this.order_id)
        }
      }
    })
  },
}
</script>

<style scoped>
div >>> #admin-unit___BV_modal_content_ {
  border-radius: 15px;
  border: none;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.9s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
