<template>
  <div>
    <b-button size="sm" pill variant="primary" @click="modalShow = !modalShow">
      <span style="" class=" font-weight-medium">Edit Order</span>
    </b-button>

    <b-modal
      v-model="modalShow"
      :id="ModalId"
      centered
      hide-footer
      hide-header
      size="md"
    >
      <b-overlay :show="loading">
        <b-container fluid class="p-4  ">
          <!-- Header Section -->
          <div class="d-flex align-items-center  justify-content-between">
            <h3 class="text-primary font-weight-bold">Edit Customer</h3>
            <p
              @click="modalShow = !modalShow"
              style="cursor:pointer; color:#D4D4D4"
            >
              Skip
            </p>
          </div>

          <b-row>
            <b-col cols="">
              <span
                >OBS! After pressing SAVE, the order will be updated with the
                new quantity and price. This is the number that will be
                invoiced.</span
              >
            </b-col>
          </b-row>

          <b-row class="mt-3">
            <b-col cols="12" sm="12" md="3" lg="3">
              <b-form-group label="Quantity" label-for="qty">
                <b-form-input
                  id="qty"
                  name="qty"
                  v-model="quantity"
                  type="number"
                  required
                />
              </b-form-group>
            </b-col>
            <b-col cols="12" sm="12" md="3" lg="3">
              <b-form-group label="Price" label-for="price">
                <b-form-input
                  id="price"
                  name="price"
                  v-model="Price"
                  type="number"
                  required
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12" class="">
              <b-button
                variant="primary"
                style="border-radius:11px"
                class="text-uppercase "
                @click="UpdateOrder"
                >save</b-button
              >
            </b-col>
          </b-row>
        </b-container>
      </b-overlay>
    </b-modal>
  </div>
</template>

<script>
import axios from "axios"
export default {
  name: "UpdateRowModal",
  props: ["index", "qty", "price", "product_id"],

  data() {
    return {
      loading: false,
      modalShow: false,
      quantity: this.qty,
      Price: this.price,
    }
  },
  computed: {
    ModalId() {
      return this.index.toString()
    },
  },
  methods: {
    async UpdateOrder() {
      const order_product_id = this.product_id
      this.loading = true
      try {
        let data = {
          order_product_id: order_product_id,
          quantity: this.quantity,
          price: this.Price,
        }
        const response = await axios.put("/internaladmin/updateorder", data)
        if (response.status == 204) {
          this.$emit("getOrders")
          this.$store.dispatch("addToastMessage", {
            type: "success",
            text: "Order Updated",
          })

          this.modalShow = !this.modalShow
        }
      } catch ({ response }) {
        this.$store.dispatch("addToastMessage", {
          type: "danger",
          text: response.data.error_message,
        })
      } finally {
        this.loading = false
      }
    },
  },
}
</script>

<style scoped>
div >>> #admin-unit___BV_modal_content_ {
  border-radius: 15px;
  border: none;
}
</style>
