<template>
  <div>
    <b-overlay :show="loading">
      <div v-if="Orders && columnValues && columnValues.length">
        <b-table responsive :items="columnValues" :fields="fields">
          <template #cell(order_id)="data">
            <span class="font-weight-bold" v-if="data.item.order_id">{{
              data.item.order_id
            }}</span>
          </template>
          <template #cell(order_date)="data">
            <span class="font-weight-bold">{{ data.item.order_date }}</span>
          </template>
          <template #cell(invoice_company_name)="data">
            <span class="font-weight-bold">{{
              data.item.invoice_company_name
            }}</span>
          </template>
          <template #cell(contact_name)="data">
            <span class="font-weight-bold">{{ data.item.contact_name }}</span>
          </template>
          <template #cell(order_line_id)="data">
            <span class="font-weight-bold">{{ data.item.order_line_id }}</span>
          </template>
          <template #cell(product_description)="data">
            <p class="">-</p>
            <div v-if="data.item.order_products.length">
              <p
                v-for="(product, index) in data.item.order_products"
                :key="index"
              >
                {{ product.products.product_description }}
              </p>
            </div>
          </template>
          <template #cell(valid_from_dt)="data">
            <p class="">-</p>
            <div v-if="data.item.order_products.length">
              <div
                v-for="(product, index) in data.item.order_products"
                :key="index"
              >
                <p v-if="product.valid_from_dt">{{ product.valid_from_dt }}</p>
                <p v-else>-</p>
              </div>
            </div>
          </template>
          <template #cell(valid_to_dt)="data">
            <p class="">-</p>
            <div v-if="data.item.order_products.length">
              <div
                v-for="(product, index) in data.item.order_products"
                :key="index"
              >
                <p v-if="product.valid_to_dt">{{ product.valid_to_dt }}</p>
                <p v-else>-</p>
              </div>
            </div>
          </template>
          <template #cell(quantity)="data">
            <p class="">-</p>
            <div v-if="data.item.order_products.length">
              <div
                v-for="(product, index) in data.item.order_products"
                :key="index"
              >
                <p>{{ product.quantity }}</p>
              </div>
            </div>
          </template>
          <template #cell(price)="data">
            <p class="">-</p>
            <div v-if="data.item.order_products.length">
              <div
                v-for="(product, index) in data.item.order_products"
                :key="index"
              >
                <p>{{ product.price }}</p>
              </div>
            </div>
          </template>
          <template #cell(discount)="data">
            <p class="">-</p>
            <div v-if="data.item.order_products.length">
              <div
                v-for="(product, index) in data.item.order_products"
                :key="index"
              >
                <p>{{ product.discount || " " }}</p>
              </div>
            </div>
          </template>
          <template #cell(invoice_date)="data">
            <span
              v-if="data.item.invoice_date"
              class="font-weight-bold"
              style="color:#5cb85c"
              >{{ data.item.invoice_date }}</span
            >
            <span v-else>-</span>
          </template>
          <template #cell(modal)="data">
            <OrderAdminstrateModal
              :index="data.index"
              :order_id="data.item.id"
              @getOrders="getOrders(buiseness_id)"
            />
            <div v-if="data.item.order_products.length">
              <div
                v-for="(product, index) in data.item.order_products"
                :key="index"
                class="mt-1"
              >
                <UpdateRowModal
                  :index="index"
                  :qty="product.quantity"
                  :price="product.price"
                  :product_id="product.id"
                  @getOrders="getOrders(buiseness_id)"
                />
              </div>
            </div>
          </template>
        </b-table>
        <div v-if="Orders.length" v-observe-visibility="ScrolledToBottom"></div>
      </div>

      <div v-else>
        <b-row class="mt-2">
          <b-col cols="12">
            <p class="text-primary ">No Orders Found</p>
          </b-col>
        </b-row>
      </div>
    </b-overlay>
  </div>
</template>

<script>
import axios from "axios"
import OrderAdminstrateModal from "@/components/System_Admin/OrderAdministrateModal.vue"
import UpdateRowModal from "@/components/System_Admin/UpdateRowModal.vue"
export default {
  props: ["buiseness_id"],
  components: {
    OrderAdminstrateModal,
    UpdateRowModal,
  },
  data() {
    return {
      fields: [
        {
          key: "order_id",
          label: "Order id",
          sortable: true,
        },
        {
          key: "order_date",
          label: "Order Date",
          sortable: true,
        },

        {
          key: "invoice_company_name",
          label: "Client",
          sortable: true,
        },
        {
          key: "contact_name",
          label: "Contact Name",
          sortable: true,
        },
        {
          key: "order_line_id",
          label: "Order line id",
          sortable: true,
        },
        {
          key: "product_description",
          label: "Product Description",
        },
        {
          key: "valid_from_dt",
          label: "Valid from date",
        },
        {
          key: "valid_to_dt",
          label: "Valid to date",
        },
        {
          key: "quantity",
          label: "Quantity",
        },
        {
          key: "price",
          label: "Price",
        },
        {
          key: "discount",
          label: "Discount",
        },
        {
          key: "invoice_date",
          label: "Invoice date",
          sortable: true,
        },
        {
          key: "modal",
          label: "",
        },
      ],
      loading: false,
      Orders: null,
      links: null,
    }
  },
  computed: {
    columnValues() {
      let arrValue = []
      if (this.Orders) {
        arrValue = this.Orders.map(v => ({
          order_id: v.id,
          order_date: v.order_date,
          invoice_company_name: v.invoice_account.invoice_company_name,
          contact_name:
            v.invoice_account.contact_first_name +
            " " +
            v.invoice_account.contact_last_name,
          order_line_id: v.external_invoice?.order_id,
          invoice_date: v.external_invoice?.invoice_date,
          id: v.id,
          order_products: v.order_products,
        }))
      }
      return arrValue
    },
  },
  watch: {
    buiseness_id() {
      this.getOrders(this.buiseness_id)
    },
  },
  methods: {
    async getOrders(id) {
      if (id) {
        this.loading = true

        try {
          let data = {
            business_unit_id: id,
          }

          const response = await axios.get(`/internaladmin/orders`, {
            params: data,
          })

          if (response.status === 200) {
            this.Orders = response.data?.data ?? null
            this.links = response.data?.links ?? null
          }
        } catch ({ response }) {
          this.$store.dispatch("addToastMessage", {
            type: "danger",
            text: response.data.item.error_message,
          })
        } finally {
          this.loading = false
        }
      }
    },
    async getOrdersPaginated(id, pageURL) {
      if (id && pageURL) {
        this.loading = true

        try {
          let data = {
            business_unit_id: id,
          }

          const response = await axios.get(pageURL, {
            params: data,
          })

          if (response.status === 200) {
            this.Orders = [...this.Orders, ...(response.data?.data ?? [])]
            this.links = response.data?.links ?? null
          }
        } catch ({ response }) {
          this.$store.dispatch("addToastMessage", {
            type: "danger",
            text: response.data.item.error_message,
          })
        } finally {
          this.loading = false
        }
      }
    },

    ScrolledToBottom(isVisible) {
      if (!isVisible) {
        return
      }
      if (this.links.more_pages) {
        this.getOrdersPaginated(this.buiseness_id, this.links.next_page_url)
      }
    },
  },
}
</script>

<style></style>
