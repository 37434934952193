<template>
  <div>
    <b-overlay :show="loading">
      <!-- Top Section -->
      <b-row class="mt-2">
        <b-col cols="12" sm="12" md="8" lg="8">
          <h3 class="text-primary font-weight-bold">
            Register customer
          </h3>
        </b-col>
      </b-row>

      <b-container fluid class="px-6 mt-4">
        <b-row class="mb-4">
          <b-col cols="12" sm="12" md="6" lg="6">
            <b-form-group
              label="Campaign"
              label-for="campaign"
              class="text-primary mb-4 font-weight-bold"
            >
              <b-form-select
                v-model="selectableCampaignID"
                id="models"
                class="mb-3 input-container"
              >
                <b-form-select-option selected disabled value="">
                  Select campaign
                </b-form-select-option>
                <b-form-select-option
                  v-for="(campaign, index) in selectableCampaigns"
                  :value="campaign.campaign_id"
                  :key="index"
                  >{{ campaign.name }}</b-form-select-option
                >
              </b-form-select>
            </b-form-group>
          </b-col>
          <b-col cols="12" sm="12" md="6" lg="6">
            <b-form-group
              label="Country of origin"
              label-for="language"
              class="text-primary mb-4 font-weight-bold"
            >
              <b-form-select
                v-model="selectableCountriesID"
                id="language"
                class="mb-3 input-container"
              >
                <b-form-select-option selected disabled value="">
                  Select country
                </b-form-select-option>
                <b-form-select-option
                  v-for="(country, index) in selectableCountries"
                  :value="country.country_code"
                  :key="index"
                  >{{ country.name }}</b-form-select-option
                >
              </b-form-select>
            </b-form-group>
          </b-col>
          <b-col cols="12" sm="12" md="6" lg="6">
            <b-form-group
              label="Campaign Start date"
              label-for="start_date"
              class="text-primary mb-4 font-weight-bold"
            >
              <b-form-datepicker
                id="start_date"
                v-model="start_dt"
                class="mb-3 input-container"
              ></b-form-datepicker>
              <small class="text-danger" v-if="error.valid_from_dt">{{
                error.valid_from_dt[0]
              }}</small>
            </b-form-group>
          </b-col>
          <b-col cols="12" sm="12" md="6" lg="6">
            <b-form-group
              label="Campaign End date"
              label-for="end_date"
              class="text-primary mb-4 font-weight-bold"
            >
              <b-form-datepicker
                id="end_date"
                v-model="end_dt"
                class="mb-3 input-container"
              ></b-form-datepicker>
              <small class="text-danger" v-if="error.valid_to_dt">{{
                error.valid_to_dt[0]
              }}</small>
            </b-form-group>
          </b-col>
          <b-col cols="12" sm="12" md="6" lg="6">
            <b-form-group
              label="Participants (set to 1 if not known)"
              label-for="participants"
              class="text-primary mb-4 font-weight-bold"
            >
              <b-form-input
                id="participants"
                v-model="Participants"
                class="participants"
                type="number"
              />
              <small class="text-danger" v-if="error.participants">{{
                error.participants[0]
              }}</small>
            </b-form-group>
          </b-col>

          <b-col
            style="border-bottom:1px solid #999999;"
            class="mx-auto mt-4"
            cols="11"
          ></b-col>
        </b-row>

        <!-- Invoice Info Row -->
        <b-row class="mb-5">
          <!-- Company Org Name -->
          <b-col cols="12" class="mt-4">
            <b-form-group
              label="Company Name"
              label-for="org_name"
              class="text-primary mb-4 font-weight-bold"
            >
              <b-form-input
                id="org_name"
                name="org_name"
                v-model="orgName"
                type="text"
                class="input-container"
              />
              <small class="text-danger" v-if="error.company_name">{{
                error.company_name[0]
              }}</small>
            </b-form-group>
          </b-col>
          <!-- Invoice Comp name -->
          <b-col cols="12" sm="12" md="6" lg="6">
            <b-form-group
              label="Invoice Company Name"
              label-for="invoice_comp"
              class="text-primary mb-4 font-weight-bold"
            >
              <b-form-input
                id="invoice_comp"
                name="invoice_comp"
                v-model="invoiceCompName"
                type="text"
                class="input-container"
              />
              <small class="text-danger" v-if="error.invoice_company_name">{{
                error.invoice_company_name[0]
              }}</small>
            </b-form-group>
          </b-col>
          <!-- Invoice Email-->
          <b-col cols="12" sm="12" md="6" lg="6">
            <b-form-group
              label="Invoice Email"
              label-for="invoice_email"
              class="text-primary mb-4 font-weight-bold"
            >
              <b-form-input
                id="invoice_email"
                name="invoice_email"
                v-model="invoiceEmail"
                type="text"
                class="input-container"
              />
            </b-form-group>
          </b-col>
          <!-- Street Address -->
          <b-col cols="12" sm="12" md="6" lg="6">
            <b-form-group
              label="Invoice Street Address"
              label-for="street_add"
              class="text-primary mb-4 font-weight-bold"
            >
              <b-form-input
                id="street_add"
                name="street_add"
                v-model="streetAdd"
                type="text"
                class="input-container"
              />
              <small class="text-danger" v-if="error.invoice_street_address">{{
                error.invoice_street_address[0]
              }}</small>
            </b-form-group>
          </b-col>
          <!-- Street Address 2 -->
          <b-col cols="12" sm="12" md="6" lg="6">
            <b-form-group
              label="Invoice Street Address 2"
              label-for="street_add2"
              class="text-primary mb-4 font-weight-bold"
            >
              <b-form-input
                id="street_add2"
                name="street_add2"
                v-model="streetAdd2"
                type="text"
                class="input-container"
              />
            </b-form-group>
          </b-col>
          <!-- Zip Code -->
          <b-col cols="12" sm="12" md="3" lg="3">
            <b-form-group
              label="Invoice Zip Code"
              label-for="zip_code"
              class="text-primary mb-4 font-weight-bold"
            >
              <b-form-input
                id="zip_code"
                name="zip_code"
                v-model="zipCode"
                type="text"
              />
            </b-form-group>
            <small class="text-danger" v-if="error.invoice_zip_code">{{
              error.invoice_zip_code[0]
            }}</small>
          </b-col>
          <!-- Zip  -->
          <b-col cols="12" sm="12" md="8" lg="8">
            <b-form-group
              label="Invoice Zip Address"
              label-for="zip"
              class="text-primary mb-4 font-weight-bold"
            >
              <b-form-input
                id="zip"
                name="zip"
                v-model="zip"
                type="text"
                class="input-container"
              />
              <small class="text-danger" v-if="error.invoice_zip">{{
                error.invoice_zip[0]
              }}</small>
            </b-form-group>
          </b-col>
          <!-- Invoice Reference  -->
          <b-col cols="12" sm="12" md="6" lg="6">
            <b-form-group
              label="Invoice Reference"
              label-for="invoice_reference"
              class="text-primary mb-4 font-weight-bold"
            >
              <b-form-input
                id="invoice_reference"
                name="invoice_reference"
                v-model="invoiceReference"
                type="text"
                class="input-container"
              />
              <small class="text-danger" v-if="error.invoice_reference">{{
                error.invoice_reference[0]
              }}</small>
            </b-form-group>
          </b-col>
          <!-- Invoice Referernce 2 -->
          <b-col cols="12" sm="12" md="6" lg="6">
            <b-form-group
              label="Invoice reference 2"
              label-for="invoice_reference2"
              class="text-primary mb-4 font-weight-bold"
            >
              <b-form-input
                id="invoice_reference2"
                name="invoice_reference2"
                v-model="invoiceReference2"
                type="text"
                class="input-container"
              />
            </b-form-group>
          </b-col>
          <b-col
            style="border-bottom:1px solid #999999;"
            class="mx-auto mt-4"
            cols="11"
          ></b-col>
        </b-row>

        <!-- Contact Information -->
        <b-row class="mb-5">
          <b-col cols="12" class="mb-2">
            <h3 class="text-primary font-weight-bold">Contact Information</h3>
          </b-col>
          <!-- First Name -->
          <b-col cols="12" sm="12" md="6" lg="6">
            <b-form-group
              label="Contact First Name"
              label-for="fName"
              class="text-primary mb-4 font-weight-bold"
            >
              <b-form-input
                id="fName"
                name="fName"
                v-model="fName"
                type="text"
                class="input-container"
              />
              <small class="text-danger" v-if="error.first_name">{{
                error.first_name[0]
              }}</small>
            </b-form-group>
          </b-col>
          <!-- Last Name -->
          <b-col cols="12" sm="12" md="6" lg="6">
            <b-form-group
              label="Contact Last Name"
              label-for="fName"
              class="text-primary mb-4 font-weight-bold"
            >
              <b-form-input
                id="lName"
                name="lName"
                v-model="lName"
                type="text"
                class="input-container"
              />
              <small class="text-danger" v-if="error.last_name">{{
                error.last_name[0]
              }}</small>
            </b-form-group>
          </b-col>
          <!-- Email -->
          <b-col cols="12" sm="12" md="6" lg="6">
            <b-form-group
              label="Contact Email"
              label-for="email"
              class="text-primary mb-4 font-weight-bold"
            >
              <b-form-input
                id="email"
                name="email"
                v-model="email"
                type="email"
                class="input-container"
              />
              <small class="text-danger" v-if="error.email">{{
                error.email[0]
              }}</small>
            </b-form-group>
          </b-col>
          <!-- Contact Phone -->
          <b-col cols="12" sm="12" md="6" lg="6">
            <b-form-group
              label="Contact Phone"
              label-for="phone"
              class="text-primary mb-4 font-weight-bold"
            >
              <b-form-input
                id="phone"
                name="phone"
                v-model="phone"
                type="tel"
                class="input-container"
              />
              <small class="text-danger" v-if="error.phone_number">{{
                error.phone_number[0]
              }}</small>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col cols="12" class="d-flex justify-content-end">
            <b-button
              class="mr-md-5"
              style="border-radius:11px"
              variant="primary"
              v-if="!spinnerLoading"
              @click="register"
              :disabled="!isValid"
            >
              <span>Create Customer</span>
            </b-button>
            <b-button
              class=" mr-md-5"
              style="border-radius:11px"
              variant="primary"
              disabled
              v-if="spinnerLoading"
            >
              <b-spinner small></b-spinner>
              <span class="sr-only">Loading...</span>
            </b-button>
          </b-col>
        </b-row>
      </b-container>
    </b-overlay>
  </div>
</template>

<script>
import axios from "axios"
export default {
  components: {},
  data() {
    return {
      loading: false,
      spinnerLoading: false,
      selectableCampaigns: [],
      selectableCampaignID: "",
      selectableCountries: [],
      selectableCountriesID: "",
      start_dt: "",
      end_dt: "",
      Participants: null,
      orgName: "",
      invoiceCompName: "",
      invoiceEmail: "",
      streetAdd: "",
      streetAdd2: "",
      zipCode: "",
      zip: "",
      invoiceReference: "",
      invoiceReference2: "",
      fName: "",
      lName: "",
      email: "",
      phone: "",
      error: {},
    }
  },
  methods: {
    resetInput() {
      this.selectableCampaignID = ""
      this.selectableCountriesID = ""
      this.start_dt = ""
      this.end_dt = ""
      this.Participants = null
      this.orgName = ""
      this.streetAdd = ""
      this.streetAdd2 = ""
      this.zipCode = ""
      this.zip = ""
      this.invoiceReference = ""
      this.invoiceReference2 = ""
      this.fName = ""
      this.lName = ""
      this.email = ""
      this.phone = ""
      this.invoiceCompName = ""
      this.invoiceEmail = ""
    },
    async getSelectableCampaigns() {
      this.loading = true

      try {
        const response = await axios.get("/internaladmin/campaigns")
        if (response.status === 200) {
          this.selectableCampaigns = response.data?.data ?? []
        }
      } catch ({ response }) {
        this.$store.dispatch("addToastMessage", {
          type: "danger",
          text: response.data.error_message,
        })
      } finally {
        this.loading = false
      }
    },
    async getSelectableCountries() {
      this.loading = true

      try {
        const response = await axios.get("/internaladmin/countries")
        if (response.status === 200) {
          this.selectableCountries = response.data?.data ?? []
        }
      } catch ({ response }) {
        this.$store.dispatch("addToastMessage", {
          type: "danger",
          text: response.data.error_message,
        })
      } finally {
        this.loading = false
      }
    },
    async register() {
      this.spinnerLoading = true

      try {
        let data = {
          first_name: this.fName,
          last_name: this.lName,
          email: this.email,
          phone_number: this.phone,
          company_name: this.orgName,
          invoice_company_name: this.invoiceCompName,
          invoice_street_address: this.streetAdd,
          invoice_zip_code: this.zipCode,
          invoice_zip: this.zip,
          invoice_reference: this.invoiceReference,
          participants: this.Participants,
          valid_from_dt: this.start_dt,
          valid_to_dt: this.end_dt,
          campaign_id: this.selectableCampaignID,
          invoice_country_code: this.selectableCountriesID,
        }
        if (this.invoiceReference2) {
          data["invoice_reference2"] = this.invoiceReference2
        }
        if (this.streetAdd2) {
          data["invoice_street_address2"] = this.streetAdd2
        }
        if (this.invoiceEmail) {
          data["invoice_email_address"] = this.invoiceEmail
        }
        const response = await axios.post("/internaladmin/customer", data)
        if (response.status == 204) {
          this.resetInput()
          this.$store.dispatch("addToastMessage", {
            type: "success",
            text: "New Customer Registered",
          })
        }
      } catch ({ response }) {
        this.error = Object.assign(response.data.error_details)
        this.$store.dispatch("addToastMessage", {
          type: "danger",
          text: response.data.error_message,
        })
      } finally {
        this.spinnerLoading = false
      }
    },
  },
  computed: {
    isValid() {
      return (
        this.fName &&
        this.lName &&
        this.email &&
        this.phone &&
        this.orgName &&
        this.streetAdd &&
        this.zipCode &&
        this.zip &&
        this.invoiceReference &&
        this.Participants &&
        this.start_dt &&
        this.end_dt &&
        this.selectableCampaignID &&
        this.selectableCountriesID &&
        this.invoiceCompName
      )
    },
  },
  watch: {},
  mounted() {
    this.getSelectableCampaigns()
    this.getSelectableCountries()
  },
}
</script>

<style scoped>
.input-container {
  max-width: 100%;
}
.participants {
  max-width: 100%;
}

@media (min-width: 770px) {
  .input-container {
    max-width: 550px;
  }
  .participants {
    max-width: 250px;
  }
}
</style>
