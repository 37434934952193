<template>
  <div>
    <b-button pill variant="primary" @click="modalShow = !modalShow">
      <span style="" class=" font-weight-medium">Edit</span>
    </b-button>

    <b-modal
      v-model="modalShow"
      :id="ModalId"
      centered
      hide-footer
      hide-header
      size="lg"
    >
      <b-overlay :show="loading">
        <b-container fluid class="p-4  ">
          <!-- Header Section -->
          <div class="d-flex align-items-center  justify-content-between">
            <h3 class="text-primary font-weight-bold">Edit Quiz</h3>
            <p
              @click="modalShow = !modalShow"
              style="cursor:pointer; color:#D4D4D4"
            >
              Skip
            </p>
          </div>

          <b-row v-if="Question">
            <b-col cols="12">
              <span class="text-primary">
                Language : {{ Question.language }}</span
              >
            </b-col>
            <b-col cols="12" class="mt-3">
              <b-form-group
                label="Question"
                label-for="question"
                class="text-primary font-weight-bold"
              >
                <b-form-input
                  id="question"
                  name="question"
                  v-model="Question.question"
                  type="text"
                />
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              class="mt-2"
              v-for="(ans, index) in Question.answers"
              :key="ans.answer_id"
            >
              <b-form-group
                :label="`Answer ${index + 1}`"
                label-for="answer"
                class="text-primary font-weight-bold"
              >
                <b-form-input
                  name="answer"
                  v-model="ans.answer"
                  type="text"
                  :class="
                    ans.correct_answer_flg === 'Y' ? 'bg-green' : 'bg-red'
                  "
                />
              </b-form-group>
            </b-col>
          </b-row>

          <b-row align-h="end">
            <b-col cols="12" class="d-flex justify-content-end mb-3 mb-sm-2 ">
              <b-button
                variant="primary"
                style="border-radius:11px"
                class="text-uppercase px-3 "
                @click="saveQuestion"
                >save</b-button
              >
            </b-col>
          </b-row>
        </b-container>
      </b-overlay>
    </b-modal>
  </div>
</template>

<script>
import axios from "axios"
export default {
  props: ["index", "question_id"],

  data() {
    return {
      loading: false,
      modalShow: false,

      Question: null,
    }
  },
  computed: {
    ModalId() {
      return this.index.toString()
    },
  },
  methods: {
    async getQuestion(id) {
      this.loading = true

      if (id) {
        try {
          let data = {
            question_id: id,
          }
          const response = await axios.get(`/internaladmin/quiz`, {
            params: data,
          })

          if (response.status === 200) {
            this.Question = response.data?.data[0] ?? null
          }
        } catch ({ response }) {
          this.$store.dispatch("addToastMessage", {
            type: "danger",
            text: response.data.error_message,
          })
        } finally {
          this.loading = false
        }
      }
    },

    async updateQuestion() {
      try {
        let data = {
          question_id: this.Question.question_id,
          question: this.Question.question,
        }
        const response = await axios.put("/internaladmin/quizquestion", data)
        if (response.status == 204) {
          this.$store.dispatch("addToastMessage", {
            type: "success",
            text: "Question Updated",
          })
        }
      } catch ({ response }) {
        this.$store.dispatch("addToastMessage", {
          type: "danger",
          text: response.data.error_message,
        })
      }
    },
    async updateAns(answer, index) {
      try {
        let data = {
          answer_id: answer.answer_id,
          answer: answer.answer,
        }

        const response = await axios.put("/internaladmin/quizanswer", data)
        if (response.status == 204) {
          this.$store.dispatch("addToastMessage", {
            type: "success",
            text: `Answer ${index + 1} updated`,
          })
        }
      } catch ({ response }) {
        this.$store.dispatch("addToastMessage", {
          type: "danger",
          text: response.data.error_message,
        })
      }
    },

    // Calls UpdateQuestion 1 time & updateAnswer "length" times
    async saveQuestion() {
      this.loading = true
      await this.updateQuestion()
      for (let i = 0; i < this.Question.answers.length; i++) {
        await this.updateAns(this.Question.answers[i], i)
      }

      this.loading = false
      this.modalShow = !this.modalShow
      this.$emit("getQuiz")
    },
  },
  mounted() {
    this.$root.$on("bv::modal::show", modalId => {
      if (modalId.componentId === this.index.toString()) {
        if (this.question_id) {
          this.getQuestion(this.question_id)
        }
      }
    })
  },
}
</script>

<style scoped>
div >>> #admin-unit___BV_modal_content_ {
  border-radius: 15px;
  border: none;
}
.bg-red {
  background: #ff575733;
}
.bg-green {
  background: #c2edda;
}
</style>
