<template>
  <div>
    <b-button variant="primary" v-b-modal="`change-user-pw-${user_id}`">
      Change
    </b-button>

    <b-modal
      :id="`change-user-pw-${user_id}`"
      title="Change user password"
      @show="resetModal"
      @hidden="resetModal"
      @ok="handleOk"
    >
      <div>
        <div class="text-muted">User email:</div>
        <div>{{ user_email }}</div>
      </div>

      <hr />

      <form ref="form" @submit.stop.prevent="submitChange">
        <b-form-group
          label="New password"
          :label-for="`change-user-pw-input-${user_id}`"
          :invalid-feedback="formFeedback"
          :state="formState"
        >
          <b-input
            :id="`change-user-pw-input-${user_id}`"
            :name="`change-user-pw-input-${user_id}`"
            type="password"
            minlength="6"
            v-model="password"
            required
          />
        </b-form-group>
        <b-form-group
          label="Confirm new password"
          :label-for="`change-user-pw-confirm-input-${user_id}`"
          :invalid-feedback="formFeedback"
          :state="formState"
        >
          <b-input
            :id="`change-user-pw-confirm-input-${user_id}`"
            :name="`change-user-pw-confirm-input-${user_id}`"
            type="password"
            minlength="6"
            v-model="password_confirmation"
            required
          />
        </b-form-group>
      </form>
    </b-modal>
  </div>
</template>

<script>
import axios from "axios"
export default {
  props: { user_id: Number, user_email: Object },
  data() {
    return {
      password: "",
      password_confirmation: "",
      formState: null,
      formFeedback: "Required and at least 6 characters",
    }
  },
  methods: {
    resetModal() {
      this.password = ""
      this.password_confirmation = ""
      this.formState = null
      this.formFeedback = "Required and at least 6 characters"
    },
    checkFormValidity() {
      const valid = this.$refs.form.checkValidity()
      this.formState = valid
      this.formFeedback = "Required and at least 6 characters"
      return valid
    },
    async handleOk(event) {
      event.preventDefault()
      await this.submitChange()
    },
    async submitChange() {
      if (!this.checkFormValidity()) {
        return
      }
      if (this.password != this.password_confirmation) {
        this.formState = false
        this.formFeedback = "Passwords do not match"
        return
      }
      await axios.put("/changepassword", {
        user_id: this.user_id,
        password: this.password,
        password_confirmation: this.password_confirmation,
      })
      this.$nextTick(() => {
        this.$bvModal.hide(`change-user-pw-${this.user_id}`)
      })
    },
  },
}
</script>
